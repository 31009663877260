/* PRODUCTS */

body.catalog-product-view {
    .column{
        padding: 0px;
        .product.available-parts{
            position: inherit;
            height: 480px;

            .non-logged-users{
                filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='4');
                -webkit-filter: blur(4px);
                filter: blur(4px);

                .select-available-parts{
                    position: absolute;
                    width: 100%;
                    z-index: 10
                }
            }
            .non-logged-container{
                height: 220px;
                width: 100%;
                z-index: 200;
                position: absolute;
                padding: 280px 0 0;

                .notice{
                    border: 1px solid #e02b27;
                    padding: 10px 20px;
                    text-align: right;
                    text-align: center;
                    margin: auto;
                    display: table;
                    background: #222323;
                    a {
                        text-decoration: none;
                        color: #3ab4f5 !important;
                    }
                }
            }
        }
    }

    .select2-container{
        &.select2-container--open {
            z-index: 999919 !important;
            display: block;
        }
    }

    &.opencontactusproduct{
        overflow:hidden;
    }
    .product-info-price .price-rfq {
        font-size: 36px;
        color: white;
    }

    .breadcrumbs {
        margin-top: 82px;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 9999;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);

        &#ContactModal{
            .modal-body{
                .sub-title{
                    color:white;
                    font-weight: 300;
                }
                ul{
                    li{
                        color:white;
                        font-weight: 300;
                        span{
                            color:white;
                            font-weight: 300;
                        }
                    }
                }
                .select2-container{
                    width:45% !important;
                    &.select2-container--open{
                        .select2-selection{
                            .select2-selection__arrow{
                                b{
                                    border-width: 0px 8px 8px;
                                }
                            }
                        }
                    }

                    .select2-results{
                        font-size: 18px;
                        line-height: 22px;
                    }
                    .select2-selection{
                        width: 100%;
                        height: auto;
                        .select2-selection__arrow{
                            padding-top: 35px;
                            padding-right: 35px;
                            b{
                                border-width: 8px 8px 0;
                            }
                        }
                        .select2-selection__rendered{
                            font-size: 18px;
                            line-height: 22px;
                            padding-left: 38px;
                            padding-top: 22px;
                            padding-bottom: 19px;
                        }
                    }
                    .select2-container--open{
                        .select2-selection{
                            border-bottom: 0;
                        }
                    }
                }

            }
        }

        .modal-content {
            position: relative;
            background-color: black;
            margin: auto;
            border: 1px solid $dark-grey-two;
            width: 80%;
            max-width:792px;
            padding:32px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
            animation-name: animatetop;
            animation-duration: 0.4s;
            z-index: 99999;

            &.modal-location-content,
            &.modal-deliverywidget-content {
                padding: 0;
            }

            .modal-header {
                padding: 2px 16px;
                color: white;
                .close {
                    &:hover,
                        &:focus {
                        color: white;
                    }
                }

                &.modal-location-header {
                    padding: 1px 13px 1px 15px;
                    color: white;
                    .close:hover{
                        color: white;
                    }
                }

                &.modal-deliverywidget-header {
                    padding: 10px 30px;
                    margin: 0px 0px 10px;
                    border-bottom: 3px solid #979797;
                }
            }


            .modal-body {
                padding: 2px 16px;
                background-color: black;

                &.modal-location-body {
                    background-color: black;
                    margin-bottom: 0px;
                    padding: 0px;

                    .google_map_warehouse{
                        padding-bottom:0px;
                    }
                }

                &.modal-deliverywidget-body {
                    padding: 0;
                    padding-bottom: 30px;
                    display: inline-block;
                    overflow-y: scroll;
                    max-height: 385px;

                    table {
                        tr {
                            &:nth-child(2n) {
                                td {
                                    background: #353636;
                                }
                            }

                            th,
                            td {
                                padding: 15px 10px;
                                color: $white;

                                &:first-child {
                                    padding-left: 30px;
                                }
                            }

                            td {
                                background: #222323;
                                color: $white;

                                &.date {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }

                .sub-title{
                    font-size:18px;
                    font-weight:lighter
                }

                select{
                    margin-top:30px;
                    max-width:332px;
                    min-height:70px;
                    font-size:18px;
                    padding-left:38px;
                    background:$dark-grey;
                }

                #product_addtocart_form {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .close {
            color: white;
            float: right;
            font-size: 40px;
            font-weight: 300;
            padding-top: 10px;
            &:hover,
                &:focus {
            }
        }
    }

    .product-options-wrapper {
        order: 2;
        position: absolute;
        margin-top: 150px;
        width: 50%;
        left: 10px;
    }

    .page-main {
        .actions{
            float: right;
        }
    }

    .toolbar-sorter .sorter{
        padding-left: 54px;
        padding-right: 94px;
        padding-bottom: 100px;
        float: none;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .product.info.detailed{
        padding: 0 20px;
        @include grid-media($desktop-growing) {
            padding-left: 0px;
            padding-right: 0px;
            margin-left: -10px;
            margin-right: 0px;
            width: 100%;
            .product.data.items{
                //margin-right: -20px;
                .data.item{
                    &.title{
                        margin-left: 20px;
                        margin-right: 20px;
                        &:first-child {
                            padding-left: 190px;
                            margin-left: 0px;
                            margin-right: 20px;
                        }
                        a{
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                    &.content{
                        padding-left: 180px;
                        .products-related{
                            .product-tabs-attribute {
                                padding-left: 0;
                            }
                        }

                    }
                }

            }

        }
    }
    .product-info-main{
        @include grid-container;
        @include grid-column(12 of 12);
        padding-top: 40px;
        @include grid-media($desktop-growing) {
            padding-top: 0;
            padding-left: 54px;
            padding-right: 94px;
            padding-bottom: 100px;
            float: none;
            margin: 0 auto;
            box-sizing: border-box;

        }

        .page-title-wrapper.product .page-title h1,
        .page-title-wrapper h1 {
            font-size: 36px;
        }

        .product-info-price .price-box {
            color: white;
        }


        .product-info-container{

            @include grid-media($desktop-growing) {
                padding-top: 0;
                margin-left: 20px;
                @include grid-column(9 of 12);
            }
            .page-title-wrapper{
                display:flex;
                img{
                    width:40px;
                    height:40px;
                }
                h1{
                    &.page-title{
                        margin-left: 10px;
                    }
                    &:last-child{
                        order:1;
                        font-weight:400;

                        @include grid-media($desktop-growing) {
                            margin:0px 20px;
                            margin-left: 43px;
                        }
                    }
                    &:first-child{
                        order:2;

                        @include grid-media($desktop-growing) {
                            margin:0px 10px;
                        }
                    }
                }
            }

            .wraptreecol-product{
                @include grid-media($desktop-growing) {
                    margin-top: 50px;
                    margin-bottom: 20px;
                    margin-left: 88px;
                }
                & > div{
                    padding-bottom: 30px;
                }

                .product-column1-manufacturer{
                    margin-right:10%;
                    @include grid-media($desktop-growing) {
                        margin-right:12%;
                    }
                }
                .product-column1-inventory{
                    td{
                        padding-left:0px;
                        padding-top:0px;
                        padding-bottom:12px;
                    }
                }
                .product-column1-manufacturer,
                .product-column1-inventory{
                    vertical-align: top;
                    display: inline-block;
                }
            }

        }
        .product-buy-container{
            position: relative;
            @include grid-media($desktop-growing) {
                @include grid-column(3 of 12);
            }

            .product-info-price {
                border:0px;
                text-align: center;
                margin-bottom: 28px;
                @include grid-media($desktop-growing) {
                    text-align:right;
                }
                .price-box.price-final_price{
                    padding-bottom: 0px;
                }
            }

            button{
                margin-bottom:20px;
            }

            .product-contact-us {
                float: right;
                clear:both;

                .contact-us {
                    border: solid 0.8px #777777;
                    padding: 9.9px 39.9px 11.4px 39.9px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #777777;
                }
                .contactbutton {
                    border: solid 0.8px white;
                    color: white;
                    margin-bottom: 10px;
                    padding: 11px 45px;
                    display: block;
                    &:hover {
                        background: $dodger-blue;
                        text-decoration: none!important;
                        border-color: $dodger-blue;
                        color: white;
                    }
                }
            }

            .product-exchanges {
                float: right;
                clear:both;

                .exchanges {
                    border: solid 0.8px #777777;
                    padding: 9.9px 39.9px 11.4px 39.9px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: center;
                    color: #777777;
                }

                .exchangebutton {
                    border: solid 0.8px white;
                    color: white;
                    margin-bottom: 10px;
                    text-align:center;
                    font-weight:500;
                    padding: 9px 12px;
                    display: block;
                    height:40px;
                    width: 161px!important;
                    font-size: 16.1px;
                    &:hover {
                        background: $dodger-blue;
                        text-decoration: none!important;
                        border-color: $dodger-blue;
                        color: white;
                    }
                }
            }

            .field.bid{
                button {
                    margin-bottom: 0;
                }
            }

            .product.attribute.overview {
                margin: 0px 0;
                margin-top:5px;
                margin-left:60px;
                font-size:18px;
                font-weight:300;
                margin-left: 85px;
            }

            .product-add-form{
                float: right;
                padding-top: 0px;
                min-width: 300px;
                position: relative;
                .messages-select-part{
                    display:none;
                    text-align: right;
                }

                .box-tocart{
                    margin-bottom: 0;
                    .fieldset {
                        margin: 0 0 0px !important;
                    }

                    .field.qty{
                        position: absolute;
                        //left: 11%;
                    }

                    .action.tocart{
                        margin-right: 0;
                        width: 161px !important;
                        font-size: 16px;
                        font-weight: 500;
                        padding: 8px 12px;
                        &.disabled{
                            &:focus{
                                background: #7d7d7d;
                                border: 1px solid #cccccc;
                            }
                        }
                    }

                    .actions{
                        display:block;
                        button{
                            float:right;
                            clear: right;
                            margin-bottom: 10px;
                        }
                    }

                    .ui-spinner.ui-widget {
                        .ui-spinner-button{
                            &.ui-spinner-up{
                                top: 0;
                            }
                        }
                    }
                }
                p.non-logged-users{
                    border: 1px solid $error;
                    padding: 10px 20px;
                    text-align: left;
                    margin-bottom: 20px;
                    width: 450px;

                    a:hover{
                        color: #3ab4f5;
                        text-decoration: none;
                    }
                }
                p.notice {
                    border: 1px solid $error;
                    padding: 10px 20px;
                    text-align: right;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .close:hover,
        .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }

    &.page-layout-used {
        .page-main {
            .columns {
                .column.main {
                    padding-bottom: 0;
                }
            }
        }

        .product-info-main {
            float: none;
        }

        .product.info.detailed {
            background: black;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: 0;
            padding-left: 20px;
            padding-right: 20px;

            .product.data.items {
                display: none;
                padding-top: 20px;
                margin-bottom: 0;

                .data.item {
                    &.content {
                        background: black;
                    }
                    .data.switch {
                        background: black;
                    }
                }


            }
        }

        .titile-available-parts{
            clear:both;
            @include grid-media($desktop-growing) {
                padding-left: 32px;
            }
        }

        .select-available-parts{

            .header {
                background-color: black;
                font-size: 16px;
                padding: 20px 10px;
                border-top: 1px solid $dark-grey-three;
            }

            tr {
                &.even {
                    background-color: $dark-grey-two;
                }

                &.open {
                    .close-part-details {
                        display: inline-block;
                    }
                }

                &.select_item_list_details {
                    @include transition(all 0.5 ease-in-out);
                    display: none;

                    &.open {
                        @include transition(all 0.5 ease-in-out);
                    }

                    > td {
                        padding: 0 0 50px;
                    }
                    .products-related{
                        .product-item-details{
                            .field.choice.related{
                                display:none;
                            }
                        }
                    }
                    .ui-tabs {
                        .ui-tabs-nav {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            border-bottom: 1px solid #fff;
                            float: left;
                            width: 100%;

                            li {
                                float: left;
                                padding: 0 20px;
                                margin-bottom: 0;
                                height: 60px;

                                @include grid-media($desktop-growing) {
                                    width: 120px;
                                }

                                &:first-child {
                                    @include grid-media($desktop-growing) {
                                        margin-left: 185px;
                                    }
                                }

                                &.ui-tabs-active {
                                    a {
                                        font-weight: bold;
                                        border-bottom: 3px solid $dodger-blue;
                                        color: #fff;
                                    }
                                }

                                a {
                                    display: inline-block;
                                    padding: 20px 0;
                                    color: $dark-white;
                                    height: 60px;
                                    box-sizing: border-box;

                                    &:hover {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }

                        .ui-tabs-panel {
                            @include grid-container;
                            display: none;

                            @include grid-media($desktop-growing) {
                                padding-top: 100px;
                                padding-left: 175px;
                                padding-right: 50px;
                            }

                            table.product-tabs-table {
                                @include grid-column(12 of 12);
                                display: block;
                                @include grid-media($desktop-growing) {
                                    @include grid-column(4 of 12);
                                }

                                &.documentation {
                                    margin-top: 30px;
                                    clear: both;
                                    @include grid-media($desktop-growing) {
                                        @include grid-column(12 of 12);
                                    }

                                    a {
                                        color: $dodger-blue;

                                        &:hover {
                                            text-decoration: none;
                                        }
                                    }
                                }

                                td.product-tabs-attribute {
                                    white-space: nowrap;
                                }
                            }

                            table.product-remarks-table {
                                @include grid-column(12 of 12);
                                clear: both;
                            }
                        }
                    }
                }
            }

            tr.select-available-inventory.select_item_list:hover {
                background-color: #000;
            }

            .parts-firstcol {
                padding-left: 60px;
            }

            th.col {
                text-align: center;
                @include grid-media($desktop-growing) {
                    text-align: left;
                }
            }

            th.col, td.col {
                padding: 5px;
                @include grid-media($desktop-growing) {
                    padding: 30px 10px;
                }

                &.parts-firstcol {
                    @include grid-media($desktop-growing) {
                        padding-left: 150px;
                        padding-right: 30px;
                        width: 1%;
                    }
                }

                &.check-part {
                    text-align: center;
                }

                &.date{
                    text-transform: uppercase;
                }

                &.close-details {
                    .close-part-details {
                        display: none;
                        color: #fff;
                        font-size: 40px;
                        line-height: 0.5;

                        &:hover {
                            text-decoration: none;
                            border: 0 none;
                        }
                    }
                }

                &.location {
                    .city, .country {
                        text-transform: capitalize;
                    }

                    .country {
                        color: #ada6a6;
                    }
                }
            }
        }
    }
}

input:not([disabled]):focus, textarea:not([disabled]):focus, select:not([disabled]):focus {
    box-shadow: none;
}

.product-info-main {
    width: 100%;

    .product-column1-manufacturer {
        > div{
            margin-bottom: 13px;
            font-size: 18px;
            padding-bottom: 13px;
        }

        .wrap-botones {
            .icon-tooltip {
                position: relative;

                .tooltip-content {
                    @include position(absolute, 40px null null 0);
                    display: none;
                    color: $dodger-blue;
                    font-size: 12px;
                    width: 300px;
                }
            }


        }
    }

    .icon {
        @include transition(none);
        background: transparent url(../images/icons_product.svg) no-repeat 0 0;
        display: inline-block;
        width: 30px;
        height: 30px;
        background-size: 120px;
        color: #fff;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        &.locationbutton {
            background-position: -62px 0;
            width: 26px;

            &:hover {
                background-position: -62px -30px;
            }
        }

        &.hazmat {
            background-position: 0 0;

            &:hover {
                background-position: 0 -30px;
            }
        }

        &.hazmat {
            background-position: 0 0;

            &:hover {
                background-position: 0 -30px;
            }
        }

        &.exportprod {
            background-position: -30px 0;

            &:hover {
                background-position: -30px -30px;
            }
        }

        &.deliverywidget {
            background-position: -90px 0;
            margin-bottom: -10px;
            margin-left: -50px;
            margin-right: 15px;

            &:hover {
                background-position: -90px -30px;
            }
        }
    }
}



.product-info-stock-sku{
    display:none !important;
}
.product-column1-inventory td ,.product-inventory-table td{
    font-size:18px;
    color:#C5C5C5 ;
    font-weight:300;
    padding: 6.5px 10px;
    width: 150px;
}
div .product-tabs-value{
    color:white !important;
}
.qty > label{display:inline-block; padding-right:20px;}
.qty > div{display:inline-block;}
.qty > div input{display:inline-block; border:0px;}
@media only screen and (max-width: 768px) {
    .page-layout-1column .product-info-main {
        width: 100%;
    }
}



.abs-product-link>a:hover, .product-item-name>a:hover, .product.name a>a:hover {
    color: white;
}

.abs-product-link>a:visited, .product-item-name>a:visited, .product.name a>a:visited {
    color: white;
}



.action.secondary:hover {
    background: transparent;
    border: 1px solid white;
    color: white;
}

button {
    background: transparent;
    border: 1px solid white;
    color: white;
}

button:not(.primary) {
    box-shadow: none;
}
.place-bid{
    width:  161px !important;
    font-size: 15.1px;
    padding: 8px 12px;
    height:40px;
    &.blueoffer{
        background: $dodger-blue;
        text-decoration: none;
        border-color: $dodger-blue;
        color: #fff!important;
    }
}

.catalogsearch-advanced-result .category-addtocart a.ui-spinner-button.ui-spinner-up.ui-corner-tr.ui-button.ui-widget.ui-state-default.ui-button-text-only {
    margin-left: -62px;
    z-index: 5;
    color: #7a7a7a;
    margin-bottom: -4px;
}

.catalogsearch-advanced-result .category-addtocart a.ui-spinner-button.ui-spinner-down.ui-corner-br.ui-button.ui-widget.ui-state-default.ui-button-text-only {
    margin-left: -62px;
    z-index: 5;
    color: #7a7a7a;
}




.product.media {
    width: 0%;
    display: none;
}

.product-item-photo {
    display: none;
}

.product-social-links {
    display: none;
}

.product.data.items>.item.title:not(.disabled)>.switch:active, .product.data.items>.item.title.active>.switch, .product.data.items>.item.title.active>.switch:focus, .product.data.items>.item.title.active>.switch:hover {
    background: $dark-grey;
    color: white;
}

.product.data.items>.item.title>.switch:visited {
    background-color: $dark-grey;
}

.product.data.items>.item.content {
    background: $dark-grey;
}



.toolbar select {
    background-color: #313131;
    box-shadow: none;
    color: white;
}

.sorter-action:hover:before {
    color: white;
}

.product-item-name>a, .product.name a>a {
    color: white !important;
}
.price{color:white; font-weight:400 !important;}
.old-price, .old.price {
    display: none;
}


.products-grid .product-item-info:hover, .products-grid .product-item-info.active {
    background: none;


}
.product-item-name>a:hover{text-decoration:none;}

/* Bid button */
.field.bid{
    float: left;
    margin: 0;

    button {
        height: 40px;
        border: none;
        color: #313131;
        margin-bottom: 0;

        &:hover {
            background-color: transparent !important;
            color: white !important;
        }
        &:before {
            content: '\e616' !important;
            font-family: 'luma-icons';
        }
        span {
            display: none;
        }
    }
    .control {
        display: flex;
        margin-left: -69px;
    }

    .input-text{
        background: #313131;
        color: white;
        border-radius: 0px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        &.bid.allow-to-bid {
            height: 40px;
            margin-top: 0px;
            margin-bottom: 0px;
            width: 125px;
            order: 2;
            &::-ms-clear {
                display:none;
                width : 0;
                height: 0;
            }
        }
    }

}

button.disabled, button[disabled], fieldset[disabled] button {
    background-color: $dark-white;
    color:white !important;
}

.block-minicart .subtotal .label {
    font-size: 18px;
    font-weight: 300;
    margin-left: 5px;
    order: 2;
}

.cart.table-wrapper .col.price, .order-items.table-wrapper .col.price, .cart.table-wrapper .col.qty, .order-items.table-wrapper .col.qty, .order-items.table-wrapper .col.subtotal, .cart.table-wrapper .col.msrp, .order-items.table-wrapper .col.msrp {
    text-align: left !important;
}


.page-products .sorter {
    float: left;
    position: relative !important;
    top: -9px;
}

dl.block.results.search {
    display: none;
}

.category-product-images {
    display: block;
    float: left;
    padding-left: 2.2%;
    padding-right: 66.9px;
    text-align: center;
    height: 151px;
    line-height: 211px;
}

.category-name-price {
    float: left;
    width: 82%;
    .product-item-name {
        width: 60%;
        float: left;
        a{
            &:hover{
                color:$dodger-blue;
            }
        }
    }
}

.category-additional-info-manufacturer {
    width: 30%;
    float: left;
}

.category-additional-info-inventory {
    width: 20%;
    float: left;
    margin-top: 7px;
}


.category-addtocart {
    width: 20%;
    float: right;
    margin-top: 14px;
}

.category-addtocart .product-item .tocart{
    width: 130px !important;
    font-size: 13px;
    padding: 8px 12px;
}

.block.block-compare {
    display: none;
}

.sidebar-main{
    padding:10px important;
}

.results{
    margin-top:0px;

}
.products{
    margin:0px;
}

.products-list{ border:0px; }

.column.main .product-items{
    margin:0px;
}

.category-additional-info-manufacturer{
    div{
        margin-top:50px;
        font-size:18px;
        &:first-child{
            margin-top:11px; font-weight:300;
        }
        &:last-child{
            font-weight:400;
        }
    }
}


.price-rfq {
    font-size: 28px;
    float: right;
    margin-bottom: -35px;
}
.product-item .price-box {
    margin: 0px 0 10px;
}

.titulo-buscado-producto{
    font-weight:100 !important;
    span{
        font-weight:400 !important;
    }
}

.filter-options-content a:hover {
    background-color:inherit !important;
    color:#3AADEB !important;
}
.bid{
    margin:10px 0;
    width:130px;
}


@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}


#info-modal{margin-top:44px; font-size:18px; font-weight:100;}
#info-modal ul{margin:0px;padding:0px;}
#info-modal li{list-style:none;}
#info-modal span{padding-left:10%;}

.cart-empty, .quote-empty{
    text-align: center;
}

.checkout-cart-index .columns .cart-empty, .quotation-quote-emptyquote .quote-empty {
    margin: 5% 10%;
}

.customer-account-logoutsuccess .columns .column.main {
    padding-left: 10%;
    padding-top: 5%;
}

#order-attributes .fieldset.address .field label {
    font-size: 22px;
    font-weight: 700 !important;
}

.form.methods-shipping .actions-toolbar .primary {
    float: left !important;
}

.category-addtocart .stock.available {
    display: none;
}

.category-name-price .price-rfq a {
    color:white !important;
}

.category-name-price .price-rfq a:hover {
    text-decoration: none !important;
}

/* AVAILABLE PARTS */

.field.choice.admin__field.admin__field-option.required {
    width: 100% !important;
    padding: 35.5px;
    margin: 0px;
}

.listado_options {
    margin-top: 10px;
}

.listado_options_titulo,
.listado_options .price-notice {
    display: none !important;
}
.invisible {
    display: none;
}




@keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}



#info-modal{margin-top:44px; font-size:18px; font-weight:100;}
#info-modal ul{margin:0px;padding:0px;}
#info-modal li{list-style:none;}
#info-modal span{padding-left:10%;}

.cart-empty, .quote-empty{
    text-align: center;
}

.checkout-cart-index .columns .cart-empty, .quotation-quote-emptyquote .quote-empty {
    margin: 5% 10%;
}

.customer-account-logoutsuccess .columns .column.main {
    padding-left: 10%;
    padding-top: 5%;
}

.category-addtocart .stock.available {
    display: none;
}

.category-name-price .price-rfq a {
    color:white !important;
}

.category-name-price .price-rfq a:hover {
    text-decoration: none !important;
}

/* AVAILABLE PARTS */

.field.choice.admin__field.admin__field-option.required {
    width: 100% !important;
    padding: 35.5px;
    margin: 0px;
}

.listado_options {
    margin-top: 10px;
}

.listado_options_titulo,
.listado_options .price-notice {
    display: none !important;
}
.invisible {
    display: none;
}

#tab-label-additional-title {
    display:none !important;
}

.page-layout-empty .product-inventory-table.catalog{
    display: none;
}

/* margin for product message*/
.page.messages>div>div>div {
    margin: 0px 0px 30px 21px;
}
#opc-new-shipping-address {
    display: none;
}

.price-description {
    font-size: 18px;
    font-weight: 300;
}

.product-tabs-line.last {
    padding-bottom: 30px;
}



.toolbar-products {
    .pages .action.next, .toolbar-products .pages .action.previous {
        margin-left: 8px;
        background: #3ab4f5;
        border: none;
        border-radius: 0px;
        box-shadow: none;
        color: #ffffff;
    }

    .pages strong.page {
        background: #3ab4f5 !important;
        color: #ffffff !important;
        line-height: 22px !important;
    }

    .pages .action.next:before {
        color: #ffffff !important;
    }

    .field.limiter select {
        width: 55px !important;
    }

    .field.limiter .select2-container.select2-container--open .select2-selection.select2-selection--single {
        border-bottom: 1px solid #aaa;
    }
}

/* Product List */
.products-list {
    .product-item-photo {
        display: none !important;
    }

    .product-item {
        .category-addtocart {
            .action {
                padding: 10px 12px;
                height: auto;
                text-align: center;
                background: none;
                border: 1px solid #fff;
                display: inherit;
                margin-bottom: 10px;
                box-sizing: border-box;

                &.hover, &:visited {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
}
