.block.upsell, 
.block.related {
    .price-box  {
        display: none;
    }

    .upsell-products-table {
        max-width: 480px;

        .product-item-details {
            a {
                color: #fff;

                &:hover {
                    color: $dodger-blue;
                    text-decoration: none;
                }
            }
        }

        .product-tabs-attribute,
        .product-tabs-value {
            font-size: 18px;
        }

        .item.product.product-item,
        .product-item-info {
            display: none;
        }
    }
}






