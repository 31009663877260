
/* LOGIN */

.modal-popup .modal-header {
    background-color: #313131;
}

.modal-popup._inner-scroll .modal-content {
    background-color: #313131;
}

.block-authentication .block[class]+.block::before {
    background: #313131;
}

input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
    background: #313131;
    color: white;
    border-radius: 0px;
    border: none;
}



.form-create-account .action.submit.primary
.form-login .action.login.primary {
    background-color: $dodger-blue !important;
    border: none !important;
    height: 70px;
    width: 203px !important;
    font-size: 20px;
}

.form-login .login .field.email .control .input-text,
.form-login .login .field.password .control .input-text {
    height: 40px;
}

.form-login .login .label {
    margin-top: 14px;
    margin-bottom: 10px;
}

.block-new-customer .action.create.primary {
    font-size: 22px;
    width: 203px !important;
    line-height: 70px;
    font-weight: normal;
}

.fieldset.login .actions-toolbar {
    margin-top: 80px;
}


.customer-account-login{
    .page.messages {
        margin-left: 97px;
    }
    .block-new-customer{
        .block-content{
            .actions-toolbar{
                .primary{
                    a{
                        border: solid 1px $dark-grey-three;
                        font-weight: 300;
                        background: transparent;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}


.message.error > *:first-child:before {
    color: $dodger-blue !important;
}

.form-login .secondary {
    margin-top: 32px;
}

.form-login .secondary .action.remind {
    font-size: 14px;
    margin-top: 32px;
    color: white;
}

.password-strength-meter {
    background-color: #313131;
}

.block-collapsible-nav .content {
    background: #313131;
    border: 1px solid;
}

.block-collapsible-nav .item.current a, .block-collapsible-nav .item.current>strong {
    border-color: white;
    color: white;
}

.block-collapsible-nav .item a:hover {
    background: #5d5d5d;
}

.block-collapsible-nav .item a {
    color: white !important;
}



.homemainimage {
    background-image: url(/media/wysiwyg/jumbo.jpg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    height: 450px;
    position: relative;
    width: 100%;

    .hometext {
        color: #010101;
        position: absolute;
        bottom: 28%;
        right: 10%;

        h1 {
            font-size: 5.3rem;
            margin: 0;
        }
    }
}


.homebrands{
    width:100%;
    max-width:100%;
    margin:0 auto;
    min-height: 380px;
    margin-top: 60px;
    display: flex;

    .singlebrand{
        a {
            transition: all 0.5s ease;
            color: white;
            display: inline-block;
        }
        a:hover{
            transition: all 0.5s ease;
            box-shadow: 1px 1px 8px #000;
            transform: scale(1.02);
            color: white;
            text-decoration: none;
        }
    }
}

@media (min-width: 588px){
    .homebrands {
        max-width:90%;
    }
}
.singlebrand{
    width:100% !important;
    background:inherit !important;
    height:auto !important;
    margin-right:42px;
    img {
        width: 100%;
    }
}
.singlebrand:last-child{
    margin-right:0px !important;
}
.singlebrandimage{background:$dark-grey-two;}
.singlebrandtext{
    padding-top:50px;
    max-height:120px !important;
    border-bottom: 2px solid #26a9e0 !important;
    background:$dark-grey-two !important;
}
.singlebrandtext p:last-child{
    font-weight:100;
}


.order-success-details {
    margin-left: 117px;
    margin-right: 50px;
    padding-top: 50px;
    margin-top: 160px;
    border-top: 1px solid $dark-grey-three;
}



.quote-date {
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    max-width: 400px;
    padding-top: 15px;
}

.order-success-header {
    float: left;
    padding-bottom: 50px;
}

.quote-success-manufacturer {
    padding-top: 14px;
    font-size: 18px;
    font-weight: 300;
}

.quote-success-condition {
    font-size: 18px;
    padding-top: 20px;
    padding-bottom: 30px;
}

.order-success-buttons {
    float: right;
    max-width: 270px;
}
.order-success-save-page, .order-success-print-page {
    float: right;
    margin-right: 20px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    color: #888989;
}

.order-success-save-page:hover, .order-success-print-page:hover {
    background: none;
    color: white;
    border: none;
}
.order-success-shipandpayment {
    margin-left: 119px;
    margin-right: 50px;
}
.order-success-address {
    text-align: left;
    margin-top: 60px;
    margin-bottom: 30px;
    width: 258px;
    float: left;
}
.order-success-address-title {
    font-size: 18px;
    padding-bottom: 38px;
}
.order-success-address-details {
    font-size: 16px;
}
.order-success-items {
    padding-top: 48px;
    padding-bottom: 30px;
    text-align: left;
    font-size: 26px;
}

.order-success-total {
    padding-top: 27px;
    font-size: 36px;
    padding-right: 123px;
    float: right;
    padding-bottom: 100px;
}

.order-success-table {
    border-top: 1px solid $dark-grey-three;
}

.order-success-table-details {
    height: 188px;
}

.order-success-sku {
    font-size: 26px;
    font-weight: 500;
    padding-top: 48px;
}

.order-success-name, .order-success-price {
    font-size: 26px;
    font-weight: 300;
    padding-top: 40px;
}

.order-success-price .total-title, .order-success-total .total-title, .order-success-qty, .projected-shipping {
    font-size: 18px;
    font-weight: 300;
    padding-top: 48px;
}


.projected-shipping p {
    font-size:16px;
}

.order-success-table-details {
    border-bottom: 1px solid $dark-grey-three;
}

.order-success-price .total-title, .order-success-total .total-title {
    padding-right: 30px;
}

/* USER ACCOUNT */
.customer-account-login,
.customer-account-create,
.customer-account-index,
.customer-account-forgotpassword,
.customer-account-createpassword  {
    .page-title-wrapper {
        border-bottom: 2px solid $dark-grey-three;
        border-top: 1px solid $dark-grey-three;
        padding-top: 24px;
        padding-bottom: 30px;
        background-color: #040404;
        .page-title{
            padding-left:32px;
        }
    }
}

.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword {
    .column.main{
        @include grid-container;
        .form{
            @include grid-column(12 of 12);

            @include grid-media($desktop-growing) {
                @include grid-column(6 of 12);
                margin: 50px 110px;
            }
            .fieldset{
                .select2-container{
                    width: 100% !important;
                }
            }
            .field{
                .password-strength-meter{
                    background:transparent;
                }
                .password-strength-meter-0{
                    display:none;
                }
            }
        }
    }
}

.login-container{
    @include grid-container;
    padding-top: 40px;
    @include grid-media($desktop-growing) {
        margin: 50px 110px;
    }
    .block{
        &.block-customer-login{
            @include grid-column(12 of 12);
            @include grid-media($desktop-growing) {
                @include grid-column(8 of 12);
            }
        }
        &.block-new-customer{
            @include grid-column(12 of 12);
            @include grid-media($desktop-growing) {
                @include grid-column(3 of 12);
            }
            .block-content{
                .actions-toolbar{
                    .primary{
                        @include grid-media($desktop-growing) {
                            margin-top: 10px;
                        }

                    }
                }

            }
        }
        .block-title {
            border: none;
            margin-bottom: 35px;
            strong {
                font-weight: 300 !important;
                font-size: 22px;
            }
        }
    }
}
.fieldset.address, .fieldset.login, .fieldset.create.info, .fieldset.create.account, .customer-account-login .block.block-new-customer .block-content, .customer-account-forgotpassword .form.password.forget {
    font-size: 18px;
    font-weight: 300;
}

.fieldset>.field>.label, .fieldset>.fields>.field>.label {
    font-weight: 300 !important;
}

.action.login.primary, .action.create.primary, .action.submit.primary {
    padding: 0px;
    width: 203px;
    height: 70px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    background-color: #3ab4f5;
    border: none;
}

.select.required-entry {
    background: #313131 !important;
    color: white !important;
}

.customer-account-login a.action.primary:hover, .customer-account-login .action.primary:hover, .customer-account-create .action.submit.primary:hover {
    background-color: $dodger-blue !important;
    border: none !important;
}

.account.customer-account-index .columns {
    margin: 0px 0px;
}
.account .sidebar-main {
    margin-top: 0px !important;
}

.account.page-layout-2columns-left .sidebar-main, .account.page-layout-2columns-left .sidebar-additional {
    display: none;
}

.account.page-layout-2columns-left .column.main {
    width: 100% !important;
}

.customer-account-index {
    .page-title-wrapper{
        .page-title {
            margin-bottom: 0px;
        }
    }
    .ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all{
        background: $dark-grey;
        border: 1px solid;
        .ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all{
            .ui-datepicker-prev.ui-corner-all, .ui-datepicker-next.ui-corner-all{
                span{
                    border-color: transparent $dodger-blue transparent $dodger-blue;
                }
            }
        }
        .ui-datepicker-calendar{
            color: black;
            .ui-datepicker-today{
                background:$dodger-blue;
                a{
                    color:white
                }
            }
            .ui-state-default:hover{
                color:white;
                background:$dodger-blue;
            }
        }
    }
}


/* USER ACCOUNT */

.field-tooltip .field-tooltip-content {
    background: #040404 !important }

.account .messages {margin-bottom: 0px !important;}

.sales-order-history .columns {
    margin: 0px !important;
}

.sales-order-history .quotes-page-title-wrapper {
    padding-left: 119px !important;
    border-bottom: 1px solid $dark-grey-three;
    border-top: 1px solid $dark-grey-three;
    padding-top: 24px;
    padding-bottom: 0px;
    background-color: #040404;
}

.sales-order-history .block-title.order {
    padding-bottom: 0px !important;
}

.sales-order-history .order-history .col.total {
    padding-right: 30%;
}

.sales-order-history .pager, .sales-order-history .page-title-wrapper{
    display: none;
}

.account.customer-account-index .page-title, .account.customer-account-index .page.messages{
    margin-bottom: 0px !important;
}
.account.customer-account-index .page.messages {
    padding-bottom: 0px !important;
}

.account.customer-account-index .block-title.order {
    padding-bottom: 0px !important;
}

.account table>thead>tr>th, table>tbody>tr>th, table>tfoot>tr>th, table>thead>tr>td, table>tbody>tr>td, table>tfoot>tr>td {
    font-size: 16px;
    font-weight: 300;
}

.block-miniquote .action.viewquote {
    color: white !important;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    border: solid 1px $dark-grey-three;
    padding: 11px 100px 15px 100px;
}

.block-miniquote .action.viewquote:hover {
    background-color: $dodger-blue;
    border: 1px solid $dodger-blue !important;
}

.block-miniquote .action.viewquote span {
    display: none;
}

.block-miniquote a.action.viewquote:after {
    content: 'View List';
}

.quotation-quote-index {
    .cart-container {
        .primary {
            max-width:203px !important;
            height:70px;
            float:left;
            display:block;
        }
    }
    .form-cart{
        padding:0px !important;
        max-width:100% !important;
        margin:0px;
        display:flex;
        flex-direction:column-reverse;
        .content{
            .note{
                width:100%;
                margin:0 auto;
                font-size: 22px;
                font-weight: bold;
            }
            .fieldset{
                &.estimate {
                    margin-left: 20px;
                    @include grid-media($desktop-growing) {
                        margin-left: 116px;
                    }
                }
                .admin__control-textarea{
                    background: #313131!important;
                    color: #fff!important;
                    border: none;
                    width: 350px;
                    height: 150px;
                }
            }
        }
    }

    .cart.table-wrapper{
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        @include grid-media($desktop-growing) {
            margin-top: 50px;
            margin-left: 116px;
            margin-right: 55px;
        }

        .product-item-name a{
            font-size:26px;
            font-weight:200;
        }
        .item{
            .item-actions{
                .action-delete{
                    left: -50px;
                    @include grid-media($desktop-growing) {
                        left: 0px;
                    }
                }
            }
            .col{
                padding-left: 0 !important;
                &.item{
                    padding: 20px 8px 20px 0;

                }
                &.qty-text{
                    display:none;
                    @include grid-media($desktop-growing) {
                        display:table-cell;
                    }
                }
                &.qty{
                    &:before{
                        content:"Qty";
                        @include grid-media($desktop-growing) {
                            content:none;
                        }
                    }
                }
                &.price {
                    .cart-price{
                        &.quote-bidding{
                            position: relative;
                            &:before{
                                content:none;
                                color:white;
                                font-size:18px;
                                font-weight:200;
                                @include grid-media($desktop-growing) {
                                    content:"Bid";
                                }
                            }
                            input{
                                background: #2e2f2f !important;
                                padding: 2px 10px;
                                min-width: 150px;
                                border: 0px;
                                text-align: center;
                                font-size: 26px;
                                &.input-text.bid{
                                    margin-left: 0;
                                    text-align: center;
                                    @include grid-media($desktop-growing) {
                                        margin-left: 50px;
                                    }
                                }
                            }
                        }
                        &:before{
                            content:none;
                            color:white;
                            font-size:18px;
                            font-weight:200;
                            @include grid-media($desktop-growing) {
                                content:"Price";
                            }
                        }
                        span{
                            font-size: 26px;
                            min-width: 150px;
                            display: inline-block;
                            text-align: center;
                        }
                        .price{
                            text-align: left;
                            @include grid-media($desktop-growing) {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .item-info .control input{
        margin-left:5px;
    }

    #shopping-cart-table{
        margin-bottom:49px;
    }
    .wrap-legend{
        border-bottom: 1px solid #c5c5c5;
        padding: 5px 0px;
    }
    .legend{
        /* padding:10px 0 0px 0px; */height: 85px;border:0px;
        span{
            border-bottom: 4px solid #39B4F5;
            margin:0 auto;
            height: 39px;
            padding-top: 47px;
            position: absolute;
            font-size: 18px;
            margin-left: 20px;
            @include grid-media($desktop-growing) {
                margin-left: 116px;
            }

        }
    }

    .cart-summary {
        float: left;
        @include grid-media($desktop-growing) {
            margin-left: 116px;
        }
    }
    .actions-toolbar .action-edit {
        display: none !important;
    }
    .page-title-wrapper{
        height:98px;
        background:#040404;
        padding-left: 119px;
        border-bottom: solid 1px $dark-grey-three;
        border-top: solid 1px $dark-grey-three;
        h1{
            display:block !important;
            position:relative;
            top: 28px;
            margin:0 auto;
        }
    }

    .field .customer-note{
        height:140px;
        max-width:340px;
        margin:22px 0px;
        background-color: #2e2f2f !important;
        border:0px;
    }


}


.quotation-quote-index .summary.title,
.quotation-quote-index .content .checkout.methods.items.checkout-methods-items .action.multicheckout {
    display: none !important;
}

/*scroll*/

::-webkit-scrollbar-button{
    display: block;
    height: 13px;
    border-radius: 0px;
    background-color: $dark-grey;
}
::-webkit-scrollbar-thumb{
    background-color: $dodger-blue;
}

::-webkit-scrollbar-track{
    background-color: $dark-grey;
}

::-webkit-scrollbar-track:hover{
    background-color: #CCC;
}

::-webkit-scrollbar{
    width: 7px;
}

.sales-guest-form .page-title-wrapper,
.cms-privacy-policy-cookie-restriction-mode .page-title-wrapper,
.catalogsearch-advanced-index .page-title-wrapper,
.contact-index-index .page-title-wrapper,
.search-term-popular .page-title-wrapper,
.cms-noroute-index .page-title-wrapper {
    padding-left: 9% !important;
    border-bottom: 1px solid $dark-grey-three;
    border-top:  1px solid $dark-grey-three;
    padding-top: 24px;
    padding-bottom: 30px;
    background-color: #040404;
}

.sales-guest-form .columns,
.cms-privacy-policy-cookie-restriction-mode .columns,
.catalogsearch-advanced-index .columns,
.contact-index-index .columns,
.search-term-popular .columns,
.cms-noroute-index  .columns {
    padding: 4% 9%;
}

.sales-guest-form .breadcrumbs, .cms-privacy-policy-cookie-restriction-mode .breadcrumbs, .catalogsearch-advanced-index .breadcrumbs, .contact-index-index .breadcrumbs, .search-term-popular .breadcrumbs {
    padding: 0px;
    margin: 0px;
}

.message.error {
    color: white !important;
    background: none !important;
}

.modal-slide._inner-scroll .modal-footer {
    background-color: #2b2c2c;
}

.modal-footer .action.secondary.action-hide-popup {
    color: white;
    text-decoration: none;
}

.modal-popup .modal-header, .modal-popup .modal-content, .modal-popup .modal-footer {
    background-color: #313131;
    font-size: 16px;
}

.modal-footer .action-primary:hover, .modal-footer .action-primary:focus, .modal-footer .action-primary:active {
    background: $dodger-blue !important;
    border: 1px solid $dodger-blue !important;
}

.message.notice{
    max-width:380px;
    background:none;
    color:white;
    z-index:9999;
    color:#C2C2C2;
    margin-left:4%;
    position:relative !important;
}
.catalogsearch-advanced-result.page-products .sorter{top:0px; right:0px;}
.catalogsearch-advanced-result .page-title-wrapper{margin-left:4%;margin-bottom:15px;}
.catalogsearch-advanced-result .found,.summary{margin-left:4%;}
.catalogsearch-advanced-result .toolbar-products{margin-bottom:0px;}
.catalogsearch-advanced-result .toolbar-sorter{background:black;padding:23px;border-top: 1px solid #8B8B8B;margin-top:43px;}
.message.notice a{margin-left:5px;  }
.message.notice a:hover{color:$dodger-blue !important; text-decoration:none !important;}

.account .order-details-items.ordered .table tfoot {
    background: $dark-grey !important;
}

.account .order-details-items tfoot td {
    background: $dark-grey !important;

}

.account .order-links .item strong {
    background: $dark-grey !important;
}

#opc-new-shipping-address {
    display: none;
}

.password-strength-meter-0 .password-strength-meter:before {
    background-color: #222323 !important;
}

.form-create-account select {
    border: none !important;
}

.form-orders-search .field.find.required .control:after,
.field.country .control:after,
.field.region .control:after,
.field-user_conditionrequest .control:after {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #777777;
    float: right;
    margin-top: -16px;
    margin-right: 7px;
    position: relative;
    content: "";
    z-index: 98;
}

.minicart-partnumber {
    font-weight: 500 !important;
}
