/*
* Quantity Control
*/
.qty {
    .ui-spinner.ui-widget {
        display: inline-block;
        position: relative;
        height: auto;

        input.input-text {
            width: 80px;
            height: 37px;
            border: none;
            padding-right: 20px;
            text-align: right;
            font-size: 18px;
            background: $brand-dark-4;
            color: #fff;
        }

        .ui-spinner-button {
            position: absolute;
            right: 2px;

            &:hover {
                cursor: pointer;
                text-decoration: none;
            }

            &.ui-spinner-up {
                top: -8px;
            }
            &.ui-spinner-down{
                bottom: -1px;
            }
        }
    }
}

/* Catalog Search page */
/* Category Catalog page */
.catalogsearch-result-index,
.catalog-category-view {
    .qty {
        .ui-spinner.ui-widget {
            position: absolute;
            left: -95px;

            .ui-spinner-button {
                &.ui-spinner-up {
                    top: 0;
                }
            }
        }
    }
}
