*{
    font-family: 'Lato', sans-serif;
}
input[type="text"]{font-family: 'Lato', sans-serif !important;  }
/* HEADER */


@media (min-width: 768px) {
    .header.content {
        max-width: 100% !important;
    }
}

.actions-secondary{
    display:none !important;
}
header{
    @include grid-container;
}
.page-header {
    border:0px !important;
    border-bottom: 1px solid $dark-grey-three !important;
    height: auto;
    background-color: #2e2f2f;
    max-height: 93px;
}

.cms-home .page.messages {
    display:none !important;
}

.columns {
    background-color: $dark-grey;
    .column.main{
        @include grid-container;
    }
}

.page-header .panel.wrapper{border:0px !important;}


.header{
    &.content {
        padding: 0px 0px 0 !important;
    }
    .block-search {
        width: 40%;
        float:left;
        /*float: right!important;*/
        .form.minisearch {
            border-left: solid 1px $dark-grey-three;
        }
    }

}

.logo img { min-width: 200px; }

.action.primary{
    background:none;
    border:1px solid white;
    &.tocart:focus,
        &.tocart:active {
        background: #e2e2e2;
        border: 1px solid #cccccc;
        color: #333333;
    }
}

.block-search .action.search {
    height: 93px;
    background-color: $dodger-blue !important;
    opacity:0px !important;
    width: 111px !important;
    margin-right: -9px !important;
    border-radius: 0px;
    opacity:10 !important;
}
.block-search .action.search:hover{
    background-color:#5EC1F7 !important;
}

.block-search .action.search:before {
    font-size:29px !important;
    line-height: 60px;
    width: 50px;
    color: white !important;
}

.search-autocomplete ul:not(:empty) {
    background:  #313131  !important;
}



.logo {
    margin: 25px auto 5px 0 !important;
    padding-left: 2%;
    padding-right: 2%;
}

/*.minicart-wrapper {
    padding-top: 17px;
    margin-right: 20px;
}

.miniquote-wrapper {
    padding-top: 17px;
    margin-right: 20px;
} */

.login-modal {
    z-index: 10;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 100%;
    height: 500px;
}
.login-modal-content {
    top: 93px;
    z-index: 20;
    position: absolute;
    right: 0px;
    background: #1b1b1b;
    width: 330px;
}

.login-modal-content .closelogin {
    font-size: 30px;
    font-weight: 300;
    margin-top: -10px;
    margin-right: 6px;
    color: #8f8f8f;
    float: right;
    cursor: pointer;
}

.login-modal-content .links{
    font-size: 18px;
    padding-left:50px;
    margin-bottom:50px;
    margin-top: 50px;
}
.login-modal-content .links a{
    color: #777777 !important;
}

.login-modal-content .links:hover {
    border-left: 5px solid $dodger-blue;
    padding-left: 45px;
}

.login-modal-content .links a:hover {
    color: white !important;
    text-decoration: none !important;
}


@media only screen and (max-width: 768px) {
    .nav-sections-item-title.active {
        background: #313131;
        border-bottom: 0;
    }
    .nav-sections-item-title {
        background: #313131;
    }
}
.nav-sections {
    background: #313131;
}

.page-header .header.panel {
    display: none !important;
}

/* BODY  PAGE  GENERAL */
.wrap-botones a{
    margin:0px 0px;
    margin-right: 31px;
}
.wrap-botones a:hover{
    opacity:0.6;
}

.page-main {
    max-width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.nav-sections {
    margin-bottom: 0px !important;
}

.page-wrapper  {
    background-color: $dark-grey;
}
footer{
    &.page-footer {
        @include grid-container;
        background: #313131 !important;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        padding-bottom: 12px !important;
        .footer.content{
            @include grid-column(12 of 12);
            box-sizing: unset;
            max-width: unset;
            padding-left: 0;
            padding-right: 0;
            margin-left: 30px;
            .block.newsletter {
                display: none;
                input {
                    padding-left: 35px;
                    background: transparent;
                }
            }
            .links{
                li {
                    border:none;
                    width: 100%;
                    float: left;
                    background: transparent;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    @include grid-media($desktop-growing) {
                        padding-right: 25px;
                        width: auto;
                        text-align: center;
                    }
                    a, strong {
                        color: white;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 300;
                        cursor: pointer;
                        &:visited {
                            color: white !important;
                        }
                        &:hover {
                            text-decoration: none !important;
                            color: #C5C5C5 !important;
                        }
                    }
                }
            }
        }
    }
    .copyright {
        @include grid-column(12 of 12);
        background-color: #313131;
    }
}


body {
    color: white;
}

a {
    color: white;
    transition:all 300ms ease-out;

    &:hover {
        color: white;
    }
    &:visited{
        color: $dodger-blue;
    }
}
.alink:visited {
    color: $dodger-blue;
}



textarea {
    background: #313131  !important;
    color: white  !important;
}

button, a.action.primary {
    border-radius: 0px !important;
}

button {
    border-radius: 0px;
}

.breadcrumbs a:visited {
    color: white;
}

.breadcrumbs a:hover {
    color: white;
}

.filter-options-title:hover {
    color: white;
}

.toolbar-products {
    margin-top: 30px;
}

.toolbar-products .pages {
    float: right !important;
    padding-right: 50px;
}

.toolbar select {
    border-radius: 0px;
    background-color: #1b1b1b !important;
    border: none;
    width: 190px;
    height: 38px;
}

.toolbar-amount {
    display: none !important;
}


.page-main>.page-title-wrapper{
    .page-title {
        display: inline-block;
        margin-bottom: 0px;
        margin-left:0;
        padding-left:32px;
    }
}


/* INTERNET EXPLORER */

.ie10 .page-wrapper, .ie11 .page-wrapper {
    height: auto !important;
}

.ie10 .page-main, .ie11 .page-main {
    flex-grow: 0 !important;
}

.ie10 .toolbar.toolbar-products, .ie11 .toolbar.toolbar-products {
    display: -ms-grid;
    margin-left: 500px;
}

.ie10 .minicart-wrapper .action.showcart .counter.qty, .ie11 .minicart-wrapper .action.showcart .counter.qty {
    margin-top: 4px !important;
    margin-left: -20px !important;
    position: relative !important;
}

.ie10 .miniquote-wrapper .action.showquote .counter.qty, .ie11 .miniquote-wrapper .action.showquote .counter.qty {
    margin-top: -0px !important;
    margin-left: -20px !important;
    position: relative !important;
}

.ie10 .ui-spinner-button, .ie11 .ui-spinner-button {
    //display: none !important;
}

.ie10 .box-tocart .fieldset .actions,
.ie11 .box-tocart .fieldset .actions {
    display: flex !important;
    flex-direction: column !important;
}

.t-center{
    text-align:center;
}


/* The Close Button */
.close_modal {
    color: white;
    position: absolute;
    font-size: 28px;
    font-weight: bold;
    left: calc(100vw - 50px);
    top: 13px;
}

.close_modal:hover,
.close_modal:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.row {
    width: 100%;
    clear: both;
    padding: 30px 0px;
}

.col-md-4 {
    width: 30%;
    float: left;
    height: 30px;
    position: relative;
    line-height: 24px;
    vertical-align: bottom !important;
}

.col-md-2 {
    width: 15%;
    float: left;
    height: 30px;
    position: relative;
    line-height: 24px;
    vertical-align: bottom !important;
}


.big_title {
    font-size: 18px;
}

.item_table_data>tbody>tr>td {
    margin: 0px;
    padding: 1px;
    table-layout: fixed;
    word-wrap: break-word;
}

.dont_show {
    display: none;
}
.strong {font-weight: bold}

/*.loader_ajax{
    background-image: url(/media/loader/loading.gif);
    height: 50px;
    width: 50px;
    position: absolute;
    top: calc(50vh - 140px);
    margin: auto;
    background-size: contain;
    margin-left: calc(50vw - 50px);
}
*/

.loader_ajax {
  position: relative;
  font-size: 14px;
  color: gray;
  border-radius: 5px;
  padding: 20px;
  pointer-events: none;

  span {
    height: $loader-thickness;
    width: $loader-width;
    display: block;
    background-color: $loader-highlight;
    margin: 0 auto;
    margin-bottom: 10px;
    &.stripe-1 {
      margin-bottom: 0;
    }
  }
}

.stripe-1 {
  animation: loader-one $loader-overall-duration $loader-curve infinite;
}

.stripe-2 {
  animation: loader-two $loader-overall-duration $loader-curve infinite;
}

.stripe-3 {
  animation: loader-three $loader-overall-duration $loader-curve infinite;
}
.stripe-4 {
  animation: loader-four $loader-overall-duration $loader-curve infinite;
}

.stripe-5 {
  animation: loader-five $loader-overall-duration $loader-curve infinite;
}


.field.choice.admin__field.admin__field-option.required {
    width: 100% !important;
    padding: 20px;
    margin: 0px;
}

.listado_options {

    width: calc(100%) !important;
}

.listado_options_titulo {
}
.invisible {
    display: none;
}

div#product-options-wrapper {
    display: none;
}

.select-available-inventory {
    cursor: pointer;

}
tr.select-available-inventory.select_item_list:hover {
    background-color: #000;
}

.catalog-product-view .product-options-wrapper {
    display: none;
}

.add_available_part {
    display: inline !important;
}


.google_map_warehouse {width: 100%; height: 50vh;}

.product-column1-manufacturer div {
    margin-bottom: 0px !important;
    font-size: 18px;
}

.sales-guest-form .form-orders-search {
    width: 50%;
}
.block-dashboard-orders{
    .message.info{
        padding-left: 66px;
    }
    .message.info>*:first-child:before{
        left: 21px;
    }
}

.viewcart:hover,.tocart:hover,.place-bid:hover{
    background:$dodger-blue !important;
    text-decoration:none !important;
    border-color:$dodger-blue !important;
    color:white !important;
}

.miniuser-link{
    height: 100%;
    background-image: url(/media/wysiwyg/imagenes/user.png)!important;
    background-position: 50%;
    background-repeat: no-repeat;
    content: "";
    display: block;
    transition: all .3s ease-out;
    min-width: 10%;
    @include grid-media($desktop-growing) {
        min-width: 100px;
    }
}

.action{
    transition:all 300ms ease-out;
}

button.action.search {
    transition: unset;
}

.footer.content {
    border-top: none;
}

.copyright{
    background-color: $brand-dark-4;
    color: $dark-grey-three;
}

.toolbar-sorter {
    .sorter-label { padding-right: 4px; }
}
body.catalog-category-view {
    .category-header-container{
        border-bottom: 1px solid #979797;

        .category-header-content-container{
            min-height:350px;
            max-height: 350px;
            width: 45%;
            float:left;
            background-color: #000000;

            .category-header-description-container{
                padding:0 65px;
                display: table-cell;
                vertical-align: middle;
                height: 350px;

                a,a:hover,a:visited,a:focus{
                    color: #3ab4f5 !important;
                }

                h1.page-title{
                    margin-bottom: 10px;
                    font-weight: 400;
                    text-indent: 7px;
                }
            }
        }
        .category-header-background-image-container{
            width: 55%;
            float:left;

            .category-header-background-image{
                height:350px;
                width:100%;
                background-position: center;
                background-size:cover;
            }
        }
        .clearfix{
            clear: both;
        }
    }
    .category-header-category-select-container{
        width:100%;
        background-color:#000;
        border-bottom: 1px solid #979797;
        padding:25px 0;
        min-height: 25px;
        a{
            .category-header-category-select-title{
                font-size: 16px;
                color: white !important;
                -o-transition:.5s;
                -ms-transition:.5s;
                -moz-transition:.5s;
                -webkit-transition:.5s;
                transition:.5s;
                span.back:after{
                    -webkit-font-smoothing: antialiased;
                    line-height: inherit;
                    color: inherit;
                    content: "\e617";
                    font-family: luma-icons;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: 400;
                    overflow: hidden;
                    speak: none;
                    margin-top: -2px;
                    margin-right: 2px;
                }
            }
        }
        a:hover,a:focus,a:active{
            text-decoration: none !important;
            .category-header-category-select-title {
                color: #3ab4f5 !important;
            }
        }
        .category-header-category-select-title{
            font-size: 18px;
            padding: 0 60px;
            font-weight: 300;
        }
        #layered-filter-block{
            top: 385px;
            left: 160px;

            .filter-title{
                font-size: 18px;
                font-weight: 300;
                margin-left: -60px;
                margin-top: -7px;
                position: absolute;
                display: inline-block;
            }

            .filter-options-item{
                width: 250px;

                .filter-options-content{
                    width: 225px;
                    max-width: 225px;
                    padding-left: 15px;
                }
            }
        }
    }
}
