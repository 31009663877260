/**
* Search Results Page
*/
body.catalogsearch-result-index,
body.catalogsearch-advanced-result,
body.catalog-category-view {
    .modal {
        display: none;
        position: fixed;
        z-index: 9999;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);

        &#ContactModal{
            .modal-body{
                .sub-title{
                    color:white;
                    font-weight: 300;
                }
                ul{
                    li{
                        color:white;
                        font-weight: 300;
                        span{
                            color:white;
                            font-weight: 300;
                        }
                    }
                }
                .select2-container{
                    width:45% !important;
                    &.select2-container--open{
                        .select2-selection{
                            .select2-selection__arrow{
                                b{
                                    border-width: 0px 8px 8px;
                                }
                            }
                        }
                    }

                    .select2-results{
                        font-size: 18px;
                        line-height: 22px;
                    }
                    .select2-selection{
                        width: 100%;
                        height: auto;
                        .select2-selection__arrow{
                            padding-top: 35px;
                            padding-right: 35px;
                            b{
                                border-width: 8px 8px 0;
                            }
                        }
                        .select2-selection__rendered{
                            font-size: 18px;
                            line-height: 22px;
                            padding-left: 38px;
                            padding-top: 22px;
                            padding-bottom: 19px;
                        }
                    }
                    .select2-container--open{
                        .select2-selection{
                            border-bottom: 0;
                        }
                    }
                }

            }
        }

        .modal-content {
            position: relative;
            background-color: black;
            margin: auto;
            border: 1px solid $dark-grey-two;
            width: 80%;
            max-width:792px;
            padding:32px;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
            animation-name: animatetop;
            animation-duration: 0.4s;
            z-index: 99999;

            &.modal-location-content,
            &.modal-deliverywidget-content {
                padding: 0;
            }

            .modal-header {
                padding: 2px 16px;
                color: white;
                .close {
                    &:hover,
                    &:focus {
                        color: white;
                    }
                }

                &.modal-location-header {
                    padding: 1px 13px 1px 15px;
                    color: white;
                    .close:hover{
                        color: white;
                    }
                }

                &.modal-deliverywidget-header {
                    padding: 10px 30px;
                    margin: 0px 0px 10px;
                    border-bottom: 3px solid #979797;
                }
            }


            .modal-body {
                padding: 2px 16px;
                background-color: black;

                &.modal-location-body {
                    background-color: black;
                    margin-bottom: 0px;
                    padding: 0px;

                    .google_map_warehouse{
                        padding-bottom:0px;
                    }
                }

                &.modal-deliverywidget-body {
                    padding: 0;
                    padding-bottom: 30px;
                    display: inline-block;
                    overflow-y: scroll;
                    max-height: 385px;

                    table {
                        tr {
                            &:nth-child(2n) {
                                td {
                                    background: #353636;
                                }
                            }

                            th,
                            td {
                                padding: 15px 10px;
                                color: $white;

                                &:first-child {
                                    padding-left: 30px;
                                }
                            }

                            td {
                                background: #222323;
                                color: $white;

                                &.date {
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }

                .sub-title{
                    font-size:18px;
                    font-weight:lighter
                }

                select{
                    margin-top:30px;
                    max-width:332px;
                    min-height:70px;
                    font-size:18px;
                    padding-left:38px;
                    background:$dark-grey;
                }

                #product_addtocart_form {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .close {
            color: white;
            float: right;
            font-size: 40px;
            font-weight: 300;
            padding-top: 10px;
            &:hover,
            &:focus {
            }
        }
    }


    /* System Messages */
    .messages {
        .message {
            margin: 20px 0 10px;
        }
    }

    .message{
        &.notice{
            margin: 0 0 30px 21px;
            .noresults{
                &:before{
                    -webkit-font-smoothing: antialiased;
                    font-size: 24px;
                    line-height: 24px;
                    color: #3ab4f5;
                    content: '\e61f';
                    font-family: 'luma-icons';
                    margin: -12px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    left: 0;
                    top: 22px;
                    width: 45px;
                    position: absolute;
                    text-align: center;
                }
            }
        }
    }

    .page-wrapper .breadcrumbs {
        border-bottom: solid 1px $dark-grey-three;
    }

    .page-main {
        position: relative;

        .columns {
            display: flex;
            position: initial;
        }
    }


    .sidebar-main {
        @include position(absolute, 0 null null 0);
        width: 100%;
        margin-right: 22px;

        // This prevents the initial button to be displayed
        > .am_shopby_apply_filters {
            display: none;
        }

        .block.filter .block-content.filter-content {
            .filter-current {
                position: absolute;
                padding-left: 70px;
                display: none;

                .item {
                    margin: 10px 5px !important;
                    float: left;
                }
            }

            .block-actions.filter-actions {
                @include position(absolute, 0 0 null null);
                position: absolute;
                z-index: 9;

                .action.filter-clear{
                    content: '';
                    display: block;
                    width: 26px !important;
                    height: 13px !important;
                    background-image: url(/media/wysiwyg/clear.png) !important;
                    padding-top: 6px;
                    margin-top: -5px;

                    span {
                        display: none !important;
                    }

                    &:hover {
                        text-decoration: none !important;
                    }
                }
            }
        }
    }

    .products li.item.product.product-item:nth-child(2n+1) {
        background-color: $dark-grey-two;
        .category-additional-info{
            .category-addtocart{
                form{
                    .ui-spinner.ui-widget.ui-widget-content.ui-corner-all{
                        .input-text.qty.spinarrow.ui-spinner-input{
                            background-color: $dark-grey;
                        }
                    }
                }

            }
        }
    }

    // Filters
    #layered-filter-block {
        position: absolute;
        top: 35px;
        right: 40px;
        margin: 0;
        padding-right: 20px;

        .filter-options{
            position:relative;
            top:-15px;
            padding-right: 30px;
        }

        .filter-options-item {
            width: 150px;
            float: left;
            margin-right: 30px;
            background-color: #1B1B1B;
            border: none;
            padding-bottom: 0;

            &.active  {
                > .filter-options-title[aria-selected=false]{
                    &:after {
                        content: '\e622';
                    }
                }
            }

            .filter-options-title {
                text-transform: none;
                font-weight: 400;
                padding: 10px 40px 10px 10px;
                margin: 0;
                border: 0 none;

                &:before {
                    display: none;
                }
            }


            .filter-options-content {
                max-width: 260px;
                min-width: 180px;
                position: absolute;
                top: 39px;
                z-index: 99999;
                background-color: #1B1B1B;

                .am_shopby_filter_items_attr_manufacturer {
                    max-height: 250px;
                    overflow-y: scroll;
                }
                input {
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 1px solid white;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 9px;
                    border-radius: 0;
                    display: inline-block;
                    position: relative;

                    &:checked {
                        background-color: $dodger-blue;
                        border: 1px solid $dodger-blue;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                        color: #99a1a7;
                    }
                }

                a {
                    position: relative;
                    color: white;
                    font-weight: 300;
                    text-transform: capitalize;
                    margin: 0 ;

                    &.am_shopby_link_selected {
                        &:before {
                            background: $dodger-blue;
                            border-color: $dodger-blue;
                        }
                    }

                    input {
                        -webkit-appearance: none;
                        background-color: transparent;
                        border: 1px solid white;
                        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                        padding: 9px;
                        border-radius: 0;
                        display: inline-block;
                        position: relative;

                        &:checked {
                            background-color: $dodger-blue;
                            border: 1px solid $dodger-blue;
                            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
                            color: #99a1a7;
                        }
                    }
                    &:before {
                    }
                }
            }
        }

        .am_shopby_apply_filters {
            float: left;
            width: auto;
            margin: 0;

            button {
                height: 38px;
                width: auto;
            }
        }
    }

    .tocart{
        width:  161px !important;
        font-size: 15.1px;
        padding: 8px 12px;
        height:40px;
        margin-bottom: 16px;
        font-weight: 500;
        &:focus,
        &:active {
            background: #e2e2e2 !important;
            border: 1px solid #cccccc !important;
            color: #333333;
        }
        &.primary{
            margin-bottom: 10px;
        }
        a{
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
        }
        a{
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
        }
    }


    .products.list.items.product-items{
        .item.product.product-item{
            padding-right: 12%;

            .product-item-details {
                @include grid-container;
                position: relative;

                .category-name-price,
                .category-additional-info {
                    @include grid-column(11 of 12);
                    @include grid-push(1);
                }

                .category-product-images {
                    @include position(absolute, 50% null null 0);
                    @include transform(translateY(-50%));
                    height: auto;
                }

                .category-addtocart {
                    p.notice {
                        border: 1px solid $error;
                        padding: 10px 20px;
                    }
                }

                .category-name-price {
                    .product-item-name {
                        @include grid-column(8 of 12);

                        a{
                            &:hover{
                                color:$dodger-blue !important;
                            }
                        }
                    }

                    .price-box {
                        @include grid-column(4 of 12);
                        height: auto;

                        .price{
                            font-size: 28px;
                            float: right;
                        }
                        &.price-final_price{
                            .price-description{
                                display: block;
                                clear: right;
                                width: 100%;
                                text-align: right;
                            }
                        }
                    }
                }

                .category-additional-info-manufacturer,
                .category-additional-info-inventory,
                .category-addtocart {
                    @include grid-column(4 of 12);
                }

                .product-item-actions {
                    float: right;
                }
            }
        }
    }
    .search.results{
        .toolbar.toolbar-products{
            .field.limiter{
                float: left;
                padding-left: 50px;

            }
            .pages{
                .item{
                    a{
                        color:white;
                    }
                }
            }
        }
    }
}
body.catalogsearch-advanced-result,
body.catalog-category-view {
    .product-item .tocart{
        width: 161px !important;
        height:40px;
        font-size: 15.1px;
        padding: 8px 12px;
        margin-bottom:16px;
    }
}
body.catalog-category-view {
    #layered-filter-block {
        top: 385px !important;

        .filter-title{
            font-size: 18px;
            font-weight: 300;
            margin-left: -60px;
            margin-top: -7px;
            position: absolute;
            display: inline-block !important;
        }
    }
    .toolbar.toolbar-products{
        .field.limiter{
            float: left;
            padding-left: 50px;
        }
        .pages{
            .item{
                a{
                    color:white;
                }
            }
        }
    }
}
