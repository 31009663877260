/* Select 2
----------------------------------- */
.select2-container{
    &.select2-container--open{
        &.select2-container--default{
            z-index: 99 !important;
        }

        .select2-selection--single{
            border-bottom:none;  
        }
    }

    &.select2-container--default {
        .select2-selection--single{
            height: auto;

            .select2-selection__arrow {
                height: 100%;
            }
        }
    }

    .select2-search--dropdown{
        display:none;
    }
    .select2-results{
        .select2-results__option{
            font-weight: 300;
            border-left: 2px solid transparent;
        }
        .select2-results__option[aria-selected=true], .select2-results__option--highlighted[aria-selected]{
            border-left: 2px solid $dodger-blue;
            background: transparent;
            font-weight: bolder;
        }
    }
    .select2-selection, .select2-results{
        background: $black-aar-two;
        border-radius: 0;
        //border: solid 1px $dark-grey-two;
        .select2-selection__rendered{
            color: #777777;
        }
    }
}

#select2-selectcountry-results{
    font-size: 18px;
    line-height: 22px;
    .select2-results__option{
        font-weight: 300;
        padding-left: 36px;
        margin-bottom: 0px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
} 