/* iCheck plugin Square skin, blue
----------------------------------- */
.icheckbox_square-blue,
.iradio_square-blue {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid white;
    cursor: pointer;
    
    &.checked {
        background: $dodger-blue;
        border-color: $dodger-blue;
    }
}
