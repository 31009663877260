
/* Data loader */
@keyframes loader-one {
  @include loader-keyframes(1);
}

@keyframes loader-two {
  @include loader-keyframes(2);
}

@keyframes loader-three {
  @include loader-keyframes(3);
}

@keyframes loader-four {
  @include loader-keyframes(4);
}

@keyframes loader-five {
  @include loader-keyframes(5);
}
