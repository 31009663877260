.expander {
    $base-font-size: 1em !default;
    $base-line-height: 1.5em !default;
    $base-spacing: 1.5em !default;
    $dark-gray: #333 !default;
    $light-gray: #DDD !default;
    $base-font-color: $dark-gray !default;
    $expander-arrow-width: 0.7em;
    $expander-toggle-size: 1em;
    $expander-toggle-arrow-size: $expander-toggle-size;
    $expander-toggle-margin: 1em;

    width: 60%;

    .expander-trigger {
        border-bottom: 1px solid $light-gray;
        color: $white;
        cursor: pointer;
        display: block;
        font-size: $expander-toggle-size;
        margin-bottom: $expander-toggle-size;
        padding-bottom: $expander-toggle-size / 4;
        text-decoration: none;
        user-select: none;

        &::before {
            content: "\25BC";
            font-size: $expander-arrow-width;
            margin-right: 0.5em;
        }
    }

    .expander-content p {
        color: $base-font-color;
        line-height: $base-line-height;
    }

    .expander-hidden {
        &::before {
            content: "\25BA";
            font-size: $expander-arrow-width;
        }
    }

    .expander-hidden + .expander-content {
        display: none;
    }
}