/**
* Checkout: Cart Page
*/
.checkout-cart-index{
    .messages{
        .message-notice.notice{
            div:first-child{
                &:before{
                    -webkit-font-smoothing: antialiased;
                    font-size: 24px;
                    line-height: 24px;
                    color: #3ab4f5;
                    content: '\e61f';
                    font-family: 'luma-icons';
                    margin: -12px 0 0;
                    vertical-align: middle;
                    display: inline-block;
                    font-weight: normal;
                    overflow: hidden;
                    speak: none;
                    left: 0;
                    top: 22px;
                    width: 45px;
                    position: absolute;
                    text-align: center;
                }
            }
        }
    }
}
.checkout-cart-index,
.quotation-quote-index {
    .item{
        .action.multicheckout{
            color: $white;

            &:visited, &:focus {
                color: $white;
            }

            &:hover{
                text-decoration: none;
            }

            span{
                border: 1px solid white;
                font-size: 20px;
                font-weight: 500;
                padding: 20px 34px;
                display: block;
                float: left;
                margin-left: 0px;
                margin-right: 30px;
                margin-top: 20px;
                &:hover{
                    background: #e1e1e1;
                    border: 1px solid #ccc;
                    color: #333;
                }
                @include grid-media($desktop-growing) {
                    margin-top: 0px;
                }
                &.continueshopping{
                    float: right;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart.item {
            .product-item-photo {
                display: none !important;
            }

            .col {
                &.qty-text {
                    text-align: right !important;
                    font-size: 18px;
                    padding-top: 20px;
                }
                &.qty{
                    input.input-text{
                        width: 80px;
                        text-align: right;
                        padding-right: 20px;
                    }
                }

                &.subtotal {
                    text-align: left;
                }
                &.uom {
                    padding: 20px 11px 10px;
                    .cart-text-uom{
                        padding-right: 35px;
                        font-size: 18px;
                    }
                }
            }
            .item-actions{
                .actions-toolbar{
                    .action-delete{
                        &:before {
                            content: 'X' !important;
                            font-family: Lato,sans-serif !important;
                        }
                    }
                }
            }
            .cart-price {
                font-size: 26px;
            }
        }

        .actions-toolbar>.action-edit:hover:before,
            .actions-toolbar>.action-delete:hover:before {
            color: white !important;
        }
    }

    .cart-summary {
        width: 28%;
        width: auto;
        background: #222323  !important;
        border: none !important;
        position: inherit !important;
        margin-right: 10%;
        padding-right: 0;

        .summary.title,
        .block.shipping,
        .totals.sub,
        .totals-tax,
        .totals.shipping.excl {
            display: none;
        }

        .checkout-methods-items {
            float: left;
            text-align: unset;
            .action.primary.checkout {
                background-color: $dodger-blue !important;
                border: none !important;
                font-size: 20px;
                font-weight: 500;
                width: 203px;
                height: 70px;
            }
            .item{
                float: right;
            }
        }

        .cart-totals {
            border-top: none;
            padding-top: 0;
        }

        .cart-totals {
            .data.table.totals {
                border-top: 2px solid #222323;
            }

            .grand strong {
                font-size: 18px !important;
                font-weight: 300 !important;

                .price {
                    font-size: 36px  !important;
                }
            }

            .grand.totals {
                line-height: 1em;
            }
        }
    }
}

/**
* Checkout: Checkout Page
*/
.checkout-container{
    @include grid-container;
    .opc-estimated-wrapper {
        display: none;
    }
    .form.methods-shipping {
        background: none;
        border:none;
    }

    .checkout-page-title-wrapper {
        padding-left: 20px;
        border-bottom: 1px solid $dark-grey-three;
        border-top:  1px solid $dark-grey-three;
        padding-top: 24px;
        padding-bottom: 30px;
        background-color: $black-aar;

        @include grid-media($desktop-growing) {
            padding-left: 119px;
        }
        .page-title {
            margin-bottom: 0px;
        }
    }


    .opc-wrapper{
        @include grid-column(12 of 12);
        padding-left: 0px;
        padding-right: 20px;

        @include grid-media($desktop-growing) {
            @include grid-column(6 of 12);
            padding-left: 94px;
        }
        .checkout-shipping-address{
            .step-title {
                border-bottom: none !important;
                color: white;
                font-size: 22px;
                font-weight: 700;
            }
            .shipping-address-item {
                border: 1px solid white;
                border: 1px solid #b7b7b7;
                width: 70%;
                padding: 30px 23px 29px 40px;
                font-size: 18px;
                font-weight: 300;
                line-height: 1.50;
                position: relative;

                @include grid-media($desktop-growing) {
                    margin-right: 25%;
                }

                &.selected-item {
                    border-color: $dodger-blue !important;
                    padding: 30px 23px 29px 40px;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1.50;

                    &:after {
                        border: 1px solid $dodger-blue;
                        border-radius: 50%;
                        background: none;
                        color: $dodger-blue;
                        right: -20%;
                        top: 45%;
                        height: 21px;
                        width: 21px;
                        font-size: 14px;
                        @include grid-media($desktop-growing) {
                        }
                    }
                }
            }
        }
        #order-attributes-form {
            position: relative !important;

            @include grid-media($desktop-growing) {
                max-width: 340px;
            }
            .select {
                color: #777777;
                height: 70px;
                font-size: 18px;
            }
        }
        .action-select-shipping-item {
            @include position(absolute, 0 null null 0);
            width: 100%;
            height: 100%;
            z-index: 2;
            margin: 0;
            border: 0;
        }


    }

    aside.modal-custom.opc-sidebar.opc-summary-wrapper.custom-slide{
        @include grid-column(12 of 12);
        visibility: initial;
        position: initial;
        @include grid-media($desktop-growing) {
            @include grid-column(4 of 12);
            @include grid-push(2);
        }

        .modal-inner-wrap{
            height: auto;
            overflow-y: initial;
            position: initial;
            -webkit-transform: none;
            transform: none;
            transition-duration: .3s;
            -webkit-transition-property: none;
            transition-property: none;
            transition-timing-function: unset;
            width: auto;

            .opc-block-summary {
                background: $black-aar-two;
                margin: 0 0 30px;

                > .title {
                    color: white;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid #ccc;
                }

                > .table-totals {
                    border-top: 0 none;

                    .grand {
                        .mark,
                        .amount {
                            border-top: none;
                        }
                    }

                    .totals {
                        &.sub,
                        &.shipping {
                            display: none;
                        }
                    }
                }

                .totals.shipping.excl .mark .label,
                .totals.shipping.excl .amount{
                    display: none;
                }

                .items-in-cart {
                    margin-bottom: 20px;

                    .minicart-items {
                        .minicart-items-wrapper{
                            max-height: 100%;
                            padding-left: 0px !important;
                            padding-right: 0px !important;

                            .product-item-details {
                                padding-left: 0;
                            }
                        }
                    }
                }

                .ship-via {
                    .table-totals {
                        td{
                            &:first-child {
                                padding-left: 0;
                            }
                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }

                td {
                    word-break: break-all;
                }
            }
        }

        .opc-block-shipping-information{
            padding: 0;

            .shipping-information {
                background: $black-aar-two;
                margin: 0 0 30px;
                padding: 22px 30px;

                .ship-via{
                    margin-bottom:0;
                }
            }
        }
    }
    .opc-estimated-wrapper{
        background: $black-aar-two;
        margin: 0;
        margin-bottom: 20px;
    }

    .action.primary{
        min-width: 190px !important;
        line-height: 2.2rem;
        padding: 14px 17px;
        font-size: 1.8rem;
        margin: 0;
    }

    .action.primary:hover,
        .action.action-show-popup:hover {
        color: white;
        background-color: #39b4f5 !important;
        border: none !important;
    }

    // Shipping Step
    .checkout-shipping-address {
        .no-shipping-address {
            display: none;
            border: 1px solid $error;
            padding: 10px 20px;
            margin-bottom: 20px;
        }

        .select2-container--default {
            width: 100% !important;

            .select2-selection__rendered {
                background: $shipping-form-input-bg;
                color: $white;
                height:32px;
                line-height:32px;
            }
        }

        i.fa-plus{
            cursor: pointer;
        }
        input,
        textarea {
            border: 1px solid #aaa;
        }

        textarea {
            height: 140px;

            @include placeholder {
                color: $placeholder-color;
            }
        }
    }
}

/**
* Checkout: Success Page
*/
body.checkout-onepage-success {
    > .actions-toolbar {
        display: none;
    }

    .checkout-success {
        text-align: center;
        margin-top: -20px;

        .checkout-success-topbackground {
            margin: 0 0 40px;
            background: url(../images/img-top-hangar.jpg) no-repeat center center;
            background-size: cover;
            max-width: 100% !important;
            border-bottom: 1px solid #8B8B8B !important;
            height: 243px;
        }

        .checkout-success-title {
            color: white;
            font-size: 36px;
            font-weight: 300;
            text-align: center;
        }

        .checkout-success-title-wrapper {
            margin-bottom: 50px;
        }

        .checkout-success-hide {
            display: none;
        }

        /*.primary {
            text-align: center;
            float: none;
        }
        .actions-toolbar .primary {
            text-align: center !important;
            float: none !important;
        }*/
        .action.primary{
            &.continue-quote {
                padding: 20px 19px;
                font-size: 20px;
                font-weight: 300;
                background-color: $dodger-blue;
                border-color: $dodger-blue;
                width: 270px;
            }
            &.continue-success {
                padding: 20px 33px;
                font-size: 20px;
                font-weight: 300;
                width: 270px;
                &:hover, &:focus, &:active {
                    background: $dodger-blue !important;
                    text-decoration: none!important;
                    border-color: $dodger-blue !important;
                    color: white !important;
                }
            }
        }
        /*.actions-toolbar-success{
            width: 50%;
            text-align: left;
            float: left;
        }*/

        .checkout-success-buttons-container {
            /*justify-content: center;
            display: flex;*/
            @include grid-container;

            .checkout-success-buttons {
                //display: flex;
                //padding-top: 40px;
                @include grid-column(12 of 12);
                text-align: center;

                p {
                    /*position: absolute;
                    top: 466px;
                    width: 500px;*/
                    margin-bottom: 30px;
                }

                .actions-toolbar,
                .actions-toolbar-success {
                    display: inline-block;
                }
            }
        }

        .order-success-details {
            margin-left: 117px;
            margin-right: 50px;
            padding-top: 50px;
            margin-top: 160px;
            border-top: 1px solid $dark-grey-three;
            .order-success-header{

            }
            .order-success-buttons{
                max-width: 100%;
                padding-right: 123px;
                .order-success-save-page{
                    float: none;
                }
                .order-success-print-page{
                    margin-right: 0px;
                    padding-right: 0px;
                }
            }
            .order-success-shipandpayment{
                clear: both;
                margin: 0;
                .order-success-address{
                    margin-top: 20px;
                }
            }
            .order-success-items{
                clear: both;
                .order-success-sku{
                    padding-left: 0px;
                }
                .order-success-price{
                    text-align: right;
                    padding-right: 123px;
                    .uom{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}


/************ NEEDS REFACTORING *******************/

#top-cart-btn-checkout {
    display: none;
}


.block-search input {
    background-color: #313131;
    height: 50px;
    color: white !important;
    border:0px;
    border-left:2px solid $dark-grey-three;
    padding-left: 42px;
    font-size: 32px;
}

@media (min-width: 953px){
    .block-search input {
        height: 93px;
    }
}

span.qs-option-name {
    font-size: 23px;
    font-weight: 300;
}

li#qs-option-0 {
    border-top: 1px solid #e5e5e5;
}

.search-autocomplete ul li:hover, .search-autocomplete ul li.selected {
    background: $black-aar;
}


.nav-toggle {
    display: none;
}

.quotation-quote-index .cart.table-wrapper .col.qty {
    text-align: left !important;
}

.quotation-quote-index .cart.table-wrapper .cart tbody td {
    text-align: left !important;
}

/* CART */
.quote-bidding input.bid {
    background: #313131  !important;
    color: white  !important;
}

.cart-totals .grand .mark,
.cart-totals .grand .amount {
    border-top: none;
}

.checkout-cart-index {
    .cart-container form.form-cart{
        padding-left: 114px;
        padding-right: 55px;
    }
}

.checkout-cart-index .cart.item .ui-button-text {
    font-size: 13px;
}

.quotation-quote-index .cart.table-wrapper .col.qty .input-text {width: 60px;}

.checkout-cart-index .cart.item .col.subtotal span.price-excluding-tax {text-align: right !important;}

.checkout-index-index .checkout-payment-method .payment-option._collapsible .payment-option-title {
    display: none;
}


.checkout-index-index .table-checkout-shipping-method .col.col-price {
    display: none;
}

.checkout-index-index .table-checkout-shipping-method .row {
    display: none;
}

div .product.data.items>.item.content{
    border:0;
}

.product.data.items>.item.title>.switch {
    padding-bottom:3px !important;
    border-left:0 !important;
    border-right:0 !important;
    border-top:0 !important;
    background:none !important;
}

.product.data.items>.item.title.active>.switch{
    border-bottom:4px solid #39B4F5 !important;
    padding-bottom:0 !important;
    font-size: 18px;
}

.product.data.items>.item.title>.switch {
    font-size: 18px;
}

div .product.data.items>.item.content {
    border-top: 1px solid #ccc;
    padding-left: 134px;
}

div#tab-label-specificationstab {
    margin-left: 108px;
}

.catalog-product-view .product-tabs-table .product-tabs-line .product-tabs-attribute {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
    color: #C5C5C5;
}

.product.data.items>.item.title>.switch {
    font-size: 18px !important;
}

.catalog-product-view .product-tabs-table .product-tabs-line .product-tabs-value {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.22;
}

.catalog-product-view .product-tabs-table {
    max-width: 450px;
}

.message.success{
    background:inherit;
    max-width:300px !important;
    margin:0 auto;
    color:white;
}

.message.success>*:first-child:before{
    color:#3184AF;
}
.filter-current .items {
    padding: 5px 10px;
    margin: 2px 0 0 0;
}
/* CATEGORY VIEW */

.catalogsearch-result-index .breadcrumbs, .checkout-cart-index .nav-sections {
    margin: 0 0 0;
    background-image: url(/media/wysiwyg/landing_gear.jpg) !important;
    background-position: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    border-bottom: 1px solid #8B8B8B !important;
    background-size: cover;
    height:243px;
    background-repeat: no-repeat;
}

.checkout-top-background {
    margin: 0 0 0;
    background-image: url(/media/wysiwyg/hangar.jpg) !important;
    background-position: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    border-bottom: 1px solid #8B8B8B !important;
    background-size: cover;
    height:243px;
    background-repeat: no-repeat;
}

.quotation-quote-index .bg-pageheader {
    margin: 0 0 0;
    background-image: url(/media/wysiwyg/landing_gear.jpg) !important;
    background-position: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    background-size: cover;
    height:243px;
    background-repeat: no-repeat;
}

.quotation-quote-success .bg-pageheader {
    margin: 0 0 0;
    background-image: url(/media/wysiwyg/hangar.jpg) !important;
    background-position: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
    margin-top: 0 !important;
    background-size: cover;
    height:243px;
    background-repeat: no-repeat;
}

.quotation-quote-success .page-title-wrapper, .quotation-quote-success .quote-number {
    display: none;
}

.quotation-quote-success .quote-text-thanks {
    margin-bottom: 50px;
}

.quotation-quote-success .page-title-wrapper-new {
    color: white;
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 39px;
    padding-top: 159px;
    border-top: 1px solid $dark-grey-three;
}

.quotation-quote-success .page.messages {
    display: none;
}

.quotation-quote-success .columns {
    text-align: center;
}

.quotation-quote-success .quotation-success {
    font-size: 18px;
}

.quotation-quote-success .actions-toolbar {
    text-align: center !important;
}

.quotation-quote-success .actions-toolbar .primary {
    float: none !important;
}

.quotation-quote-success .actions-toolbar .action.primary.continue {
    font-size: 20px;
    font-weight: 500;
    padding: 20px 34px 26px 34px;
    &:hover, &:focus, &:active {
        background: $dodger-blue !important;
        text-decoration: none!important;
        border-color: $dodger-blue !important;
        color: white !important;
    }
}

.catalogsearch-result-index .products-list{
    /* width: calc(100% - 50px);*/
}
.catalogsearch-result-index .page-title-wrapper .page-title {
    margin-left: 4%;
    font-size: 28px !important;

   .search-page-title{
      cursor: pointer;
   }
    .on-hover-search-page-title{
      display: none;
      position: relative;
      z-index: 1000;
      background:black;
      cursor: pointer;
      margin-top: -10px;
      padding-top: 10px;
   }
}

.page-main>.page-title-wrapper .page-title {
    font-size: 36px;
    font-weight: 300;
}

.checkout-top-background {
    margin: 0 !important;
}

.breadcrumbs .items {
    display: none;
}


.catalogsearch-result-index .page-title-wrapper {
    background-color: $black-aar;
    border-bottom: 1px solid $border-line-color;
    padding-top: 24px;
    padding-bottom: 29px;
}


li.item.product.product-item {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 211px;
}

.checkout-cart-index .page-title-wrapper {
    border-bottom: 1px solid $dark-grey-three;
    border-top:  1px solid $dark-grey-three;
    padding-top: 24px;
    padding-bottom: 30px;
    background-color: $black-aar;
}

.checkout-cart-index .page-title-wrapper .page-title {
    font-size: 36px !important;
}

.checkout-cart-index .columns .column.main, .quotation-quote-emptyquote .columns .column.main, .customer-account-logoutsuccess .columns .column.main {
    font-size: 18px;
}



.cart-menu-tab-review {
    font-size: 18px;
    padding-bottom: 16px;
    margin-right: 60px;
    border-bottom: 4px solid #39B4F5;
}
.cart-menu-tab {
    margin-right: 60px;
    font-size: 18px;
}

.cart-menu-section a:visited, .alink:visited{
    color: $dark-white;
}

.cart-menu-section a:hover, .alink:hover {
    text-decoration: none;
}

.cart.table-wrapper {
    margin-top: 50px;
}
.cart .cart-headings {
    display: none !important;
}

.cart.table-wrapper .product-item-name {
    font-size: 26px;
    font-weight: 300;
}

.abs-shopping-cart-items-desktop, .block-cart-failed, .cart-container .form-cart, .cart-container .cart-gift-item {
    width: 100% !important;
    padding-left: 10%;
    padding-right: 10% !important;
}

.quotation-quote-index .cart-container .cart.items.data.table thead {
    display: none !important;
}

.quotation-quote-index .cart-container .cart-summary {
    padding: 1px 2px 25px !important;
}

.cart .product-cart-item-sku {
    font-size: 26px;
    font-weight: 400;
}

.cart .product-cart-item-name {
    font-size: 26px;
    font-weight: 300;
}

.cart .product-cart-item-name a:visited, .cart .product-cart-item-name .alink:visited, .cart .product-cart-item-name a:hover {
    color: white !important;
    text-decoration: none !important;
}

.cart .product-cart-manufacturer {
    font-size: 18px;
    margin-top: 14px;
}

.cart .product-cart-condition {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 15px;
}

/* CHECKOUT */
.cart-menu {
    border-bottom: 1px solid $dark-grey-three;
    margin-top: 30px;
    padding-left: 20px;
    margin-bottom: 30px;
    display: flex;
    @include grid-media($desktop-growing) {
        padding-left: 116px;
    }
}



.opc-progress-bar {
    display: none !important;
}

.opc-sidebar .items-in-cart .product-item-details .details-qty .label:after {
    display:none;
}

button.action.edit-address-link {
    display: none;
}

button.action.action-select-shipping-item:hover {
    background-color: rgba(128, 128, 128, 0.43) !important;
}

.action.action-select-shipping-item span {
    display: none;
}

.product-image-wrapper {
    display: none;
}

.cart-discount {
    width: 50% !important;
    margin-left: 10% !important;
    order: 2 !important;
    display: none;
}

.paypal-review-discount .block>.title strong, .cart-discount .block>.title strong {
    color: white;
}

.cart-discount .primary .action.apply.primary {
    color: white;
}

@media (max-width: 588px) {
    .homebrands{
        max-width:94%;
        flex-direction:column;
        box-sizing:border-box;
        margin:0px !important;

    }
}
.homemainimage img{width:100%;}

.checkout-cart-index .action.action-edit {
    display: none !important;
}

.checkout-cart-index .action.update button:hover {
    background: $dodger-blue;
    border: none;
    color: white;
}


.checkout-payment-method {
    .payment-option-title .action-toggle {
        color: white  !important;
    }

    .checkout-billing-address .billing-address-details {
        line-height: 27px;
        padding: 10px 10px 10px 23px;
        border: 1px solid;
    }
}

.checkout-billing-address .field.field-select-billing {
    margin-top: -23px;
}

.checkout-payment-method .payment-method-content {
    display: block !important;
}
.form.form-purchase-order, .checkout-payment-method .payment-method, .checkout-billing-address {
    font-size: 18px;}


.opc-wrapper .step-title,
.opc-block-shipping-information .shipping-information-title,
.checkout-agreements-items .checkout-agreements-item-title {
    color: white !important;
}



.checkout-cart-index .cart-empty, .quotation-quote-emptyquote .quote-empty {
    padding: 40px 100px;
    font-size: 18px;
    font-weight: 300;
}

.checkout-cart-index .cart-empty a, .quotation-quote-emptyquote .quote-empty a {
    color: #ccc;
}

.quotation-quote-emptyquote .page-title-wrapper, .customer-account-logoutsuccess .page-title-wrapper {
    padding-left: 119px !important;
    border-bottom: 1px solid $dark-grey-three;
    border-top: 1px solid $dark-grey-three;
    padding-top: 24px;
    padding-bottom: 30px;
    background-color: $black-aar;
}
.checkout-index-index{
    .checkout-payment-method .payment-option._collapsible .payment-option-title {
        display: none;
    }
    .table-checkout-shipping-method .col.col-price {
        display: none;
    }
    .table-checkout-shipping-method .row {
        display: none;
    }
    .action.primary{
        width: 161px !important;
    }
    .opc-wrapper .checkout-shipping-address .action-show-popup {
        display: none;
    }
    .opc-block-summary{
        .items-in-cart{
            .product-item-details {
                .product-item-inner{
                    display: inline-block;
                    position: relative;
                    .product-item-name-block{
                        display: inline-block;
                        .product-name-data{
                            position: absolute;
                            left: 0;
                            .product-item-name{
                                display:inline-block;
                                font-weight: 300;
                            }
                        }
                        .details-qty {
                            margin-top: 30px;
                            .label{
                                margin-left: 10px;
                            }
                        }
                    }
                    .subtotal{
                        float: left;
                        display: inline-block;
                        margin-right: 20px;
                        margin-top: 25px;
                    }
                }
            }

            .minicart-items .product-item-details {
                padding-left: 15%;
                @include grid-media($desktop-growing) {
                    padding-left: 18%;
                }

            }
        }

        .minicart-items-wrapper {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .checkout-agreements{margin-bottom: 40px;}

}


.checkout-index-index .opc-block-summary .totals.shipping.excl .mark .label,
.checkout-index-index .opc-block-summary .totals.shipping.excl .amount{
    display: none;
}

.checkout-index-index label.custom-file-upload.action.primary.checkout{
    border: 1px solid #ccc;
    display: inline-block;
    text-align:center;
    line-height: 2.2rem;
    padding: 14px 17px;
    font-size: 1.8rem;
    margin: 10px 0 20px;
    cursor: pointer;
}

.checkout-index-index .action.primary:hover,
.checkout-index-index .action.action-show-popup:hover {
    color: white;
    background-color: #39b4f5 !important;
    border: 1px solid #39b4f5 !important;
}

.checkout-index-index .minicart-items .product-image-container .product-image-wrapper,
.checkout-index-index .minicart-items .product-image-container .product-image-container,
.checkout-index-index .minicart-items .product-image-container {
    height: 0px !important;
    width: 0px !important;
    display: none !important;
}

#purchaseorder-form>.payment>.field>label.label {
    display: none;
}

#shipping_method_select {
    display: none;
}



.cart-menu .cart-menu-section-review a:visited, .cart-menu-section-review .alink:visited {
    color: white !important;
}
.checkout-shipping-address .order-attributes label.label {
    font-weight: 700 !important;
}
.checkout-shipping-address .order-attributes {
    width: 400px;
}
.checkout-shipping-address .order-attributes .fieldset.address .control:after {
    //width: 0;
    //height: 0;
    //border-left: 6px solid transparent;
    //border-right: 6px solid transparent;
    //border-top: 6px solid #777777;
    //position: absolute;
    //top: 23%;
    //right: 12px;
    //content: "";
    //z-index: 98;
    display: none;
}

.quotation-quote-index .cart-price.quote-bidding:after {
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    line-height: 20px;
    color: #8f8f8f;
    content: '\e601';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
}

.checkout-cart-index .cart.item .cart-text-price {
    float: right;
    padding-right: 35px;
    font-size: 18px;
}

.checkout-cart-index    .cart.item .price-excluding-tax {
    float: right;
}

.order-success-number {
    font-size: 32px;
    text-align: left;
    max-width: 400px;
}


#order-attributes .fieldset.address .field label {
    font-size: 22px;
    font-weight: 700 !important;
}

.form.methods-shipping .actions-toolbar .primary {
    float: left !important;
}

#opc-shipping_method>.checkout-shipping-method>.step-title {
    display: none!important;
}
/**
 * Tooltip Styles
 */

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 150%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsla(0, 0%, 20%, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  opacity: 1;
}
#co-payment-form .order-attributes{
    display: none !important;
}
.checkout-onepage-success .page-header {
    min-height: 112px;
}
