/**
 * Copyright © 2013-2017 Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */
@media only screen and (min-device-width: 320px) and (max-device-width: 780px) and (orientation: landscape) {
  .product-video {
    height: 100%;
    width: 81%;
  }
}
@media all and (min-width: 768px), print {
  .abs-product-options-list-desktop dt,
  .block-giftregistry-shared .item-options dt {
    clear: left;
    float: left;
    margin: 0 10px 5px 0;
  }
  .block-giftregistry-shared .item-options dt:after {
    content: ': ';
  }
  .abs-product-options-list-desktop dd,
  .block-giftregistry-shared .item-options dd {
    display: inline-block;
    float: left;
    margin: 0 0 5px;
  }
  .abs-button-desktop {
    width: auto;
  }
  .abs-blocks-2columns,
  .abs-discount-block-desktop .block,
  .login-container .block,
  .account .column.main .block:not(.widget) .block-content .box,
  .block-addresses-list .items.addresses > .item,
  .form-address-edit .fieldset,
  .form-edit-account .fieldset,
  .storecredit .block,
  .paypal-review-discount .block,
  .cart-discount .block {
    width: 48%;
  }
  .abs-discount-block-desktop .block:nth-child(1),
  .login-container .block:nth-child(1),
  .account .column.main .block:not(.widget) .block-content .box:nth-child(1),
  .block-addresses-list .items.addresses > .item:nth-child(1),
  .form-address-edit .fieldset:nth-child(1),
  .form-edit-account .fieldset:nth-child(1),
  .storecredit .block:nth-child(1),
  .paypal-review-discount .block:nth-child(1),
  .cart-discount .block:nth-child(1) {
    clear: left;
    float: left;
  }
  .abs-discount-block-desktop .block:nth-child(2),
  .login-container .block:nth-child(2),
  .account .column.main .block:not(.widget) .block-content .box:nth-child(2),
  .block-addresses-list .items.addresses > .item:nth-child(2),
  .form-address-edit .fieldset:nth-child(2),
  .form-edit-account .fieldset:nth-child(2),
  .storecredit .block:nth-child(2),
  .paypal-review-discount .block:nth-child(2),
  .cart-discount .block:nth-child(2) {
    float: right;
  }
  .abs-discount-block-desktop .block:nth-child(2) + *,
  .login-container .block:nth-child(2) + *,
  .account .column.main .block:not(.widget) .block-content .box:nth-child(2) + *,
  .block-addresses-list .items.addresses > .item:nth-child(2) + *,
  .form-address-edit .fieldset:nth-child(2) + *,
  .form-edit-account .fieldset:nth-child(2) + *,
  .storecredit .block:nth-child(2) + *,
  .paypal-review-discount .block:nth-child(2) + *,
  .cart-discount .block:nth-child(2) + * {
    clear: both;
  }
  .abs-margin-for-blocks-and-widgets-desktop,
  .page-main .block,
  .customer-review .product-details {
    margin-bottom: 50px;
  }
  .abs-reset-left-margin-desktop,
  .column.main .paypal-review .actions-toolbar,
  .column.main .block-giftregistry-shared-items .actions-toolbar {
    margin-left: 0;
  }
  .abs-action-remove-desktop,
  .abs-add-fields-desktop .fieldset .additional .action.remove,
  .form-giftregistry-share .fieldset .additional .action.remove,
  .form-giftregistry-edit .fieldset .additional .action.remove,
  .form-create-return .fieldset .additional .action.remove,
  .form.send.friend .fieldset .additional .action.remove {
    margin-left: 90%;
  }
  .abs-add-fields-desktop .fieldset .field .control,
  .form-giftregistry-share .fieldset .field .control,
  .form-giftregistry-edit .fieldset .field .control,
  .form-create-return .fieldset .field .control,
  .form.send.friend .fieldset .field .control {
    width: auto;
  }
  .abs-margin-for-forms-desktop {
    margin-left: 25.8%;
  }
  .abs-visually-hidden-desktop,
  .modes-label,
  .block-search .label,
  .block-collapsible-nav .title {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .abs-add-clearfix-desktop:before,
  .abs-add-clearfix-desktop:after,
  .paypal-review .block-content:before,
  .paypal-review .block-content:after,
  .paypal-review-discount:before,
  .paypal-review-discount:after,
  .order-review-form:before,
  .order-review-form:after,
  .block-cart-failed .block-content:before,
  .block-cart-failed .block-content:after,
  .cart-container:before,
  .cart-container:after,
  .login-container:before,
  .login-container:after,
  .account .page-title-wrapper:before,
  .account .page-title-wrapper:after,
  .account .column.main .block:not(.widget) .block-content:before,
  .account .column.main .block:not(.widget) .block-content:after,
  .block-addresses-list .items.addresses:before,
  .block-addresses-list .items.addresses:after,
  .block-giftregistry-shared .item-options:before,
  .block-giftregistry-shared .item-options:after,
  .data.table .gift-wrapping .nested:before,
  .data.table .gift-wrapping .nested:after,
  .data.table .gift-wrapping .content:before,
  .data.table .gift-wrapping .content:after,
  .block-wishlist-management:before,
  .block-wishlist-management:after,
  .magento-rma-guest-returns .column.main .block.block-order-details-view:before,
  .magento-rma-guest-returns .column.main .block.block-order-details-view:after,
  .order-links:before,
  .order-links:after,
  .account .column.main .block.block-order-details-view:before,
  .account .column.main .block.block-order-details-view:after,
  [class^='sales-guest-'] .column.main .block.block-order-details-view:before,
  [class^='sales-guest-'] .column.main .block.block-order-details-view:after,
  .sales-guest-view .column.main .block.block-order-details-view:before,
  .sales-guest-view .column.main .block.block-order-details-view:after,
  .page-header .header.panel:before,
  .page-header .header.panel:after,
  .header.content:before,
  .header.content:after {
    content: '';
    display: table;
  }
  .abs-add-clearfix-desktop:after,
  .paypal-review .block-content:after,
  .paypal-review-discount:after,
  .order-review-form:after,
  .block-cart-failed .block-content:after,
  .cart-container:after,
  .login-container:after,
  .account .page-title-wrapper:after,
  .account .column.main .block:not(.widget) .block-content:after,
  .block-addresses-list .items.addresses:after,
  .block-giftregistry-shared .item-options:after,
  .data.table .gift-wrapping .nested:after,
  .data.table .gift-wrapping .content:after,
  .block-wishlist-management:after,
  .magento-rma-guest-returns .column.main .block.block-order-details-view:after,
  .order-links:after,
  .account .column.main .block.block-order-details-view:after,
  [class^='sales-guest-'] .column.main .block.block-order-details-view:after,
  .sales-guest-view .column.main .block.block-order-details-view:after,
  .page-header .header.panel:after,
  .header.content:after {
    clear: both;
  }
  .abs-add-box-sizing-desktop,
  .abs-shopping-cart-items-desktop,
  .column.main,
  .sidebar-main,
  .sidebar-additional,
  .bundle-options-container .block-bundle-summary,
  .block.crosssell,
  .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  .account .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  [class^='sales-guest-'] .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  .block-cart-failed,
  .cart-container .form-cart,
  .cart-container .cart-gift-item {
    box-sizing: border-box;
  }
  .abs-add-box-sizing-desktop-m,
  .opc-wrapper {
    box-sizing: border-box;
  }
  .abs-revert-field-type-desktop .fieldset > .field,
  .abs-revert-field-type-desktop .fieldset .fields > .field {
    margin: 0 0 20px;
  }
  .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .label,
  .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .label {
    box-sizing: content-box;
    float: none;
    width: auto;
    text-align: left;
    padding: 0;
  }
  .abs-revert-field-type-desktop .fieldset > .field:not(.choice) > .control,
  .abs-revert-field-type-desktop .fieldset .fields > .field:not(.choice) > .control {
    float: none;
    width: auto;
  }
  .abs-revert-field-type-desktop .fieldset > .field > .label,
  .abs-revert-field-type-desktop .fieldset .fields > .field > .label {
    margin: 0 0 8px;
    display: inline-block;
  }
  .abs-revert-field-type-desktop .fieldset > .field.choice:before,
  .abs-revert-field-type-desktop .fieldset .fields > .field.choice:before,
  .abs-revert-field-type-desktop .fieldset > .field.no-label:before,
  .abs-revert-field-type-desktop .fieldset .fields > .field.no-label:before {
    display: none;
  }
  .abs-form-field-column-2 .fieldset .field,
  .form-giftregistry-share .fieldset .field,
  .form-giftregistry-edit .fieldset .field,
  .form-create-return .fieldset .field,
  .form.send.friend .fieldset .field {
    padding: 0 12px 0 0;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
  .abs-form-field-column-2 .fieldset .field + .fieldset,
  .form-giftregistry-share .fieldset .field + .fieldset,
  .form-giftregistry-edit .fieldset .field + .fieldset,
  .form-create-return .fieldset .field + .fieldset,
  .form.send.friend .fieldset .field + .fieldset {
    clear: both;
  }
  .form-giftregistry-share .fieldset .field:nth-last-child(1),
  .form-giftregistry-share .fieldset .field:nth-last-child(2),
  .form-giftregistry-edit .fieldset .field:nth-last-child(1),
  .form-giftregistry-edit .fieldset .field:nth-last-child(2),
  .form-create-return .fieldset .field:nth-last-child(1),
  .form-create-return .fieldset .field:nth-last-child(2),
  .form.send.friend .fieldset .field:nth-last-child(1),
  .form.send.friend .fieldset .field:nth-last-child(2) {
    margin-bottom: 0;
  }
  .abs-form-field-column-2 .fieldset .field .field,
  .form-giftregistry-share .fieldset .field .field,
  .form-giftregistry-edit .fieldset .field .field,
  .form-create-return .fieldset .field .field,
  .form.send.friend .fieldset .field .field {
    padding: 0;
    width: 100%;
  }
  .abs-form-field-revert-column-1 {
    width: 100%;
  }
  .abs-forms-general-desktop,
  .form-giftcard-redeem,
  .form-giftregistry-create {
    max-width: 500px;
  }
  .abs-forms-general-desktop .legend,
  .form-giftcard-redeem .legend,
  .form-giftregistry-create .legend {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .abs-forms-general-desktop .legend + br,
  .form-giftcard-redeem .legend + br,
  .form-giftregistry-create .legend + br {
    display: none;
  }
  .abs-revert-side-paddings,
  .checkout-cart-index .page-title-wrapper,
  .cart-summary .block .content,
  .cart-empty {
    padding-left: 0;
    padding-right: 0;
  }
  .abs-account-block-font-size,
  .block-addresses-list address,
  .box-billing-address .box-content,
  .box-shipping-address .box-content,
  .box-information .box-content,
  .block-balance-giftcard .block-content,
  .block-reviews-dashboard .product-name {
    font-size: 1.6rem;
  }
  .abs-account-table-margin-desktop,
  .table-wrapper.balance-history,
  .table-wrapper.reward-history,
  .table-wrapper.orders-recent {
    margin-top: -25px;
  }
  .abs-action-print {
    display: inline-block;
    text-decoration: none;
  }
  .abs-action-print:before {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    content: '\e624';
    font-family: 'luma-icons';
    margin: 0 4px 0 0;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .abs-no-display-desktop,
  .sidebar .block.widget .pager .item:not(.pages-item-next):not(.pages-item-previous) {
    display: none;
  }
  .abs-status-desktop,
  .return-status,
  .order-status {
    margin-top: 6px;
    padding: 5px 10px;
  }
  .abs-title-orders-desktop .page-main .page-title-wrapper .order-date,
  .magento-rma-returns-returns .page-main .page-title-wrapper .order-date,
  .magento-rma-returns-view .page-main .page-title-wrapper .order-date,
  .account .page-main .page-title-wrapper .order-date,
  [class^='sales-guest-'] .page-main .page-title-wrapper .order-date,
  .sales-guest-view .page-main .page-title-wrapper .order-date {
    margin: -14px 0 18px;
  }
  .abs-table-bordered-desktop {
    border: none;
  }
  .abs-table-bordered-desktop > thead > tr > th,
  .abs-table-bordered-desktop > tbody > tr > th,
  .abs-table-bordered-desktop > tfoot > tr > th,
  .abs-table-bordered-desktop > thead > tr > td,
  .abs-table-bordered-desktop > tbody > tr > td,
  .abs-table-bordered-desktop > tfoot > tr > td {
    border: none;
  }
  .abs-table-bordered-desktop > thead > tr > th,
  .abs-table-bordered-desktop > thead > tr > td {
    border-bottom: 1px solid #cccccc;
  }
  .abs-pager-toolbar,
  .account .toolbar,
  .toolbar-giftregistry-results,
  .toolbar-wishlist-results {
    position: relative;
  }
  .abs-pager-toolbar .toolbar-amount,
  .abs-pager-toolbar .limiter,
  .account .toolbar .toolbar-amount,
  .account .toolbar .limiter,
  .toolbar-giftregistry-results .toolbar-amount,
  .toolbar-giftregistry-results .limiter,
  .toolbar-wishlist-results .toolbar-amount,
  .toolbar-wishlist-results .limiter {
    position: relative;
    z-index: 1;
  }
  .abs-pager-toolbar .toolbar-amount,
  .account .toolbar .toolbar-amount,
  .toolbar-giftregistry-results .toolbar-amount,
  .toolbar-wishlist-results .toolbar-amount {
    line-height: 30px;
    padding: 0;
  }
  .abs-pager-toolbar .pages,
  .account .toolbar .pages,
  .toolbar-giftregistry-results .pages,
  .toolbar-wishlist-results .pages {
    position: absolute;
    width: 100%;
    z-index: 0;
  }
  .abs-shopping-cart-items-desktop,
  .block-cart-failed,
  .cart-container .form-cart,
  .cart-container .cart-gift-item {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 4%;
    position: relative;
  }
  .abs-discount-block-desktop .block > .title,
  .paypal-review-discount .block > .title,
  .cart-discount .block > .title {
    border: 0;
    padding: 0 0 10px;
  }
  .abs-discount-block-desktop .block > .title strong,
  .paypal-review-discount .block > .title strong,
  .cart-discount .block > .title strong {
    font-size: 1.6rem;
  }
  .abs-discount-block-desktop .block .content,
  .paypal-review-discount .block .content,
  .cart-discount .block .content {
    padding: 0 0 20px;
  }
  .abs-discount-block-desktop .actions-toolbar .secondary,
  .paypal-review-discount .actions-toolbar .secondary,
  .cart-discount .actions-toolbar .secondary {
    bottom: -30px;
    left: 0;
    position: absolute;
  }
  h1 {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .navigation,
  .breadcrumbs,
  .page-header .header.panel,
  .header.content,
  .footer.content,
  .page-wrapper > .widget,
  .page-wrapper > .page-bottom,
  .block.category.event,
  .top-container,
  .page-main {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto;
  }
  .page-main {
    width: 100%;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto;
  }
  .ie9 .page-main {
    width: auto;
  }
  .columns {
    display: block;
  }
  .column.main {
    min-height: 300px;
  }
  .page-layout-1column .column.main {
    width: 100%;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-3columns .column.main {
    width: 58.33333333%;
    display: inline-block;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-left .column.main {
    width: 79.16666667%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-right .column.main {
    width: 79.16666667%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .sidebar-main {
    padding-right: 2%;
  }
  .page-layout-3columns .sidebar-main {
    width: 20.83333333%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-left .sidebar-main {
    width: 20.83333333%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-right .sidebar-main {
    width: 20.83333333%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-layout-2columns-right .sidebar-main {
    padding-left: 2%;
    padding-right: 0;
  }
  .sidebar-additional {
    clear: right;
    padding-left: 2%;
  }
  .page-layout-3columns .sidebar-additional {
    width: 20.83333333%;
    float: right;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .page-layout-2columns-left .sidebar-additional {
    width: 20.83333333%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-right .sidebar-additional {
    width: 20.83333333%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .page-layout-2columns-left .sidebar-additional {
    clear: left;
    float: left;
    padding-left: 0;
    padding-right: 2%;
  }
  .panel.header {
    padding: 10px 20px;
  }
  .nav-toggle {
    display: none;
  }
  .nav-sections {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    margin-bottom: 25px;
  }
  .nav-sections-item-title {
    display: none;
  }
  .nav-sections-item-content {
    display: block !important;
  }
  .nav-sections-item-content > * {
    display: none;
  }
  .nav-sections-item-content > .navigation {
    display: block;
  }
  .navigation {
    background: #f0f0f0;
    font-weight: 700;
    height: inherit;
    left: auto;
    overflow: inherit;
    padding: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3;
  }
  .navigation:empty {
    display: none;
  }
  .navigation ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    position: relative;
  }
  .navigation li.level0 {
    border-top: none;
  }
  .navigation .level0 {
    margin: 0 10px 0 0;
    display: inline-block;
    position: relative;
  }
  .navigation .level0:last-child {
    margin-right: 0;
    padding-right: 0;
  }
  .navigation .level0 > .level-top {
    color: #575757;
    line-height: 47px;
    padding: 0 12px;
    text-decoration: none;
    box-sizing: border-box;
    position: relative;
  }
  .navigation .level0 > .level-top:hover,
  .navigation .level0 > .level-top.ui-state-focus {
    color: #333333;
    text-decoration: none;
  }
  .navigation .level0.active > .level-top,
  .navigation .level0.has-active > .level-top {
    border-color: #ff5501;
    border-style: solid;
    border-width: 0 0 3px;
    color: #333333;
    text-decoration: none;
    display: inline-block;
  }
  .navigation .level0.parent:hover > .submenu {
    overflow: visible !important;
  }
  .navigation .level0 .submenu {
    background: #ffffff;
    border: 1px solid #cccccc;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
    font-weight: 400;
    min-width: 230px;
    padding: 15px 0;
    display: none;
    left: 0;
    margin: 0 !important;
    padding: 0;
    position: absolute;
    z-index: 1;
    margin-top: 11px;
  }
  .navigation .level0 .submenu > ul {
    margin-top: 11px;
  }
  .navigation .level0 .submenu > ul:before,
  .navigation .level0 .submenu > ul:after {
    content: '';
    display: block;
    overflow: hidden;
    position: absolute;
  }
  .navigation .level0 .submenu > ul:before {
    color: #ffffff;
    left: 20px;
    top: -20px;
    border: 10px solid transparent;
    height: 0;
    width: 0;
    border-bottom-color: #ffffff;
    z-index: 4;
  }
  .navigation .level0 .submenu > ul:after {
    border: 11px solid transparent;
    height: 0;
    width: 0;
    border-bottom-color: #cccccc;
    color: #cccccc;
    left: 19px;
    top: -22px;
    z-index: 3;
  }
  .navigation .level0 .submenu a {
    display: block;
    line-height: inherit;
    color: #575757;
    padding: 8px 20px;
  }
  .navigation .level0 .submenu a:hover,
  .navigation .level0 .submenu a.ui-state-focus {
    background: #e8e8e8;
    color: #333333;
    text-decoration: none;
  }
  .navigation .level0 .submenu .active > a {
    border-color: #ff5501;
    border-style: solid;
    border-width: 0 0 0 3px;
    color: #333333;
  }
  .navigation .level0 .submenu .submenu {
    top: 0 !important;
    left: 100% !important;
  }
  .navigation .level0 .submenu .submenu-reverse {
    left: auto !important;
    right: 100%;
  }
  .navigation .level0.more {
    position: relative;
    display: inline-block;
    text-decoration: none;
  }
  .navigation .level0.more:after {
    -webkit-font-smoothing: antialiased;
    font-size: 26px;
    line-height: inherit;
    color: inherit;
    content: '\e607';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .navigation .level0.more:before {
    display: none;
  }
  .navigation .level0.more:after {
    cursor: pointer;
    padding: 8px 12px;
    position: relative;
    z-index: 1;
  }
  .navigation .level0.more:hover > .submenu {
    overflow: visible !important;
  }
  .navigation .level0.more li {
    display: block;
  }
  .panel.header .links,
  .panel.header .switcher {
    display: inline-block;
  }
  .legend {
    border-bottom: 1px solid #c5c5c5;
  }
  .product.data.items {
    position: relative;
    z-index: 1;
    border-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .product.data.items:before,
  .product.data.items:after {
    content: '';
    display: table;
  }
  .product.data.items:after {
    clear: both;
  }
  .product.data.items > .item.title {
    float: left;
    width: auto;
  }
  .product.data.items > .item.title > .switch {
    height: 20px;
    display: block;
    position: relative;
    z-index: 2;
  }
  .product.data.items > .item.content {
    margin-top: 20px;
    box-sizing: border-box;
    float: right;
    margin-left: -100%;
    width: 100%;
  }
  .product.data.items > .item.content:before,
  .product.data.items > .item.content:after {
    content: '';
    display: table;
  }
  .product.data.items > .item.content:after {
    clear: both;
  }
  .product.data.items > .item.content.active {
    display: block;
  }
  .product.data.items > .item.title {
    margin: 0 -1px 0 0;
  }
  .product.data.items > .item.title > .switch {
    font-weight: 400;
    line-height: 40px;
    font-size: 1.4rem;
    color: #7d7d7d;
    text-decoration: none;
    background: #f6f6f6;
    border: 1px solid #cccccc;
    border-bottom: none;
    height: 40px;
    padding: 1px 35px 1px 35px;
  }
  .product.data.items > .item.title > .switch:visited {
    color: #7d7d7d;
    text-decoration: none;
  }
  .product.data.items > .item.title > .switch:hover {
    color: #7d7d7d;
    text-decoration: none;
  }
  .product.data.items > .item.title > .switch:active {
    color: #333333;
    text-decoration: none;
  }
  .product.data.items > .item.title:not(.disabled) > .switch:focus,
  .product.data.items > .item.title:not(.disabled) > .switch:hover {
    background: #ffffff;
  }
  .product.data.items > .item.title:not(.disabled) > .switch:active,
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    background: #ffffff;
    color: #333333;
  }
  .product.data.items > .item.title.active > .switch,
  .product.data.items > .item.title.active > .switch:focus,
  .product.data.items > .item.title.active > .switch:hover {
    padding-bottom: 2px;
  }
  .product.data.items > .item.content {
    background: #ffffff;
    margin-top: 43px;
    padding: 35px 35px 35px 35px;
    border: 1px solid #cccccc;
  }
  .product.data.items .item.title a:after {
    display: none;
  }
  .actions-toolbar {
    text-align: left;
  }
  .actions-toolbar:before,
  .actions-toolbar:after {
    content: '';
    display: table;
  }
  .actions-toolbar:after {
    clear: both;
  }
  .actions-toolbar .primary {
    float: left;
  }
  .actions-toolbar .primary,
  .actions-toolbar .secondary {
    display: inline-block;
  }
  .actions-toolbar .primary a.action,
  .actions-toolbar .secondary a.action {
    display: inline-block;
  }
  .actions-toolbar .primary .action {
    margin: 0 15px 0 0;
  }
  .actions-toolbar .secondary a.action {
    margin-top: 6px;
  }
  .actions-toolbar > .primary,
  .actions-toolbar > .secondary {
    margin-bottom: 0;
  }
  .actions-toolbar > .primary .action,
  .actions-toolbar > .secondary .action {
    margin-bottom: 0;
    width: auto;
  }
  .popup-content .fieldset .actions-toolbar .secondary {
    display: inline-block;
    float: none;
  }
  .popup-content .fieldset .actions-toolbar .action.cancel {
    margin-top: 6px;
  }
  .payment-method-braintree .cvv .field-tooltip {
    left: 6rem;
  }
  .braintree-paypal-account {
    border-color: #cccccc;
    border-style: solid;
    border-width: 1px 0;
    display: inline-block;
    width: 50%;
  }
  .account .table-credit-cards .col.actions {
    width: 100px;
  }
  .block-category-event.block:last-child {
    margin-bottom: 30px;
    padding: 10px 0 30px;
  }
  .block-category-event .block-title {
    margin: 0;
  }
  .block-category-event .block-title strong {
    font-size: 2.4rem;
  }
  .block-category-event .ticker li {
    display: none;
    margin: 0 50px;
  }
  .block-category-event .ticker .value {
    font-size: 6rem;
  }
  .block-category-event .ticker .label {
    font-size: 1.4rem;
    text-transform: none;
  }
  .block-category-event .dates .date {
    font-size: 5rem;
  }
  .block-category-event .dates .start {
    padding-right: 50px;
  }
  .block-category-event .dates .start:after {
    font-size: 5rem;
    right: 10px;
  }
  .paypal-review .paypal-review-title {
    border-bottom: 1px solid #cccccc;
  }
  .paypal-review .block-content .box-order-shipping-address,
  .paypal-review .block-content .box-order-shipping-method,
  .paypal-review .block-content .box-order-shipping-method + .box-order-billing-address {
    box-sizing: border-box;
    float: left;
    width: 33%;
  }
  .paypal-review .block-content .box-order-shipping-address {
    padding: 0 5%;
    width: 34%;
  }
  .paypal-review .table-paypal-review-items .col.price,
  .paypal-review .table-paypal-review-items .col.qty {
    text-align: center;
  }
  .paypal-review .table-paypal-review-items .col.item {
    width: 60%;
  }
  .paypal-review .col.subtotal,
  .paypal-review .mark,
  .paypal-review .amount {
    text-align: right;
  }
  .paypal-review-discount {
    border-top: 1px solid #cccccc;
  }
  .paypal-review-discount .block {
    margin-bottom: 15px;
  }
  .paypal-review-discount .block.giftcard.active {
    padding-bottom: 40px;
  }
  .paypal-review-discount .block .title:after {
    display: inline;
    margin-left: 10px;
    position: static;
  }
  .paypal-review-discount .block .content {
    padding-bottom: 0;
  }
  .order-review-form .actions-toolbar > .primary {
    float: right;
  }
  .order-review-form .action.primary {
    margin-right: 0;
  }
  .block-cart-failed .secondary .action {
    margin: 0;
  }
  .block-cart-failed .actions.primary {
    float: right;
  }
  .bundle-actions .action.primary.customize {
    width: auto;
  }
  .bundle-options-container .legend.title {
    font-size: 40px;
  }
  .bundle-options-container .bundle-options-wrapper,
  .bundle-options-container .product-options-wrapper {
    float: left;
    width: 57%;
  }
  .bundle-options-container .block-bundle-summary {
    float: right;
    margin-top: 66px;
    padding: 10px 20px;
    position: relative;
    width: 40%;
  }
  .bundle-options-container .block-bundle-summary .price-box .price-wrapper,
  .bundle-options-container .block-bundle-summary .price-box .price-wrapper > .price {
    color: #575757;
    font-size: 36px;
    font-weight: 600;
    line-height: 36px;
  }
  .bundle-options-container .block-bundle-summary .price-container .weee {
    color: #575757;
  }
  .bundle-options-container .block-bundle-summary .price-container .weee .price {
    font-size: 1.2rem;
    font-weight: 700;
  }
  .bundle-options-container .block-bundle-summary .price-including-tax + .price-excluding-tax .price {
    font-size: 1.4rem;
    line-height: 16px;
  }
  .bundle-options-container .block-bundle-summary .box-tocart .action.primary {
    margin-right: 1%;
    width: 49%;
  }
  .bundle-options-container .block-bundle-summary .product-addto-links {
    text-align: left;
  }
  .page-layout-2columns-left .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-left .bundle-options-container .block-bundle-summary,
  .page-layout-2columns-right .bundle-options-container .bundle-options-wrapper,
  .page-layout-2columns-right .bundle-options-container .block-bundle-summary,
  .page-layout-3columns .bundle-options-container .bundle-options-wrapper,
  .page-layout-3columns .bundle-options-container .block-bundle-summary {
    width: 48%;
  }
  .page-products .products-grid .product-item {
    width: 33.33333333%;
  }
  .page-products.page-layout-1column .products-grid .product-item {
    width: 25%;
  }
  .page-products.page-layout-3columns .products-grid .product-item {
    width: 50%;
  }
  .page-products .columns {
    padding-top: 0;
    position: relative;
    z-index: 1;
  }
  .toolbar-amount {
    display: block;
    float: left;
    position: static;
  }
  .products.wrapper ~ .toolbar .pages {
    float: left;
    margin-bottom: 0;
  }
  .modes {
    display: inline-block;
    float: left;
    margin-right: 20px;
  }
  .products.wrapper ~ .toolbar .modes {
    display: none;
  }
  .modes-mode {
    background-color: #f0f0f0;
    box-shadow: inset 0 1px 0 0 #ffffff, inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
    color: #7d7d7d;
    border: 1px solid #cccccc;
    border-right: 0;
    float: left;
    font-weight: 400;
    line-height: 1;
    padding: 7px 10px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
  }
  .modes-label + .modes-mode {
    border-radius: 3px 0 0 3px;
  }
  .modes-mode:hover {
    color: #7d7d7d;
    background: #ebebeb;
  }
  .modes-mode:last-child {
    border-radius: 0 3px 3px 0;
    border-right: 1px solid #cccccc;
  }
  .modes-mode.active {
    box-shadow: inset 0 1px 0 0 rgba(204, 204, 204, 0.8), inset 0 -1px 0 0 rgba(204, 204, 204, 0.3);
    background: #dedede;
    color: #9e9e9e;
  }
  .modes-mode > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .modes-mode:before {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: inherit;
    color: #7d7d7d;
    content: '\e60d';
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .modes-mode:hover:before {
    color: #7d7d7d;
  }
  .page-products .sorter {
    position: static;
  }
  .mode-list:before {
    content: '\e60b';
  }
  .limiter {
    float: right;
  }
  .products.wrapper ~ .toolbar .limiter {
    display: block;
  }
  .product-info-main .page-title-wrapper h1 {
    margin-bottom: 15px;
  }
  .product-info-main .product-add-form {
    padding-top: 20px;
  }
  .box-tocart .action.tocart {
    margin-bottom: 0;
    margin-right: 1%;
    width: 49%;
  }
  .product-social-links {
    text-align: left;
  }
  .product-options-bottom .price-box .price-container,
  .product-info-price .price-box .price-container {
    font-size: 21px;
  }
  .product-options-bottom .price-box .price-container .price,
  .product-info-price .price-box .price-container .price {
    font-size: 36px;
    line-height: 36px;
  }
  .product-options-bottom .price-box .price-including-tax + .price-excluding-tax .price,
  .product-info-price .price-box .price-including-tax + .price-excluding-tax .price {
    font-size: 1.4rem;
    line-height: 16px;
  }
  .product-info-main {
    float: right;
  }
  .product.media {
    float: left;
    margin-bottom: 25px;
  }
  .page-layout-1column .product-info-main {
    width: 40%;
  }
  .page-layout-1column .product.media {
    width: 57%;
  }
  .page-layout-2columns-left .product-info-main,
  .page-layout-2columns-right .product-info-main,
  .page-layout-3columns .product-info-main {
    width: 48%;
  }
  .page-layout-2columns-left .product.media,
  .page-layout-2columns-right .product.media,
  .page-layout-3columns .product.media {
    width: 50%;
  }
  .product-add-form .product-options-wrapper .field:not(.date) > .control {
    width: 80%;
  }
  .sidebar .product-items .product-item-info .product-item-photo {
    float: left;
    left: auto;
    margin: 0 10px 10px 0;
    position: relative;
    top: auto;
  }
  .sidebar .product-items .product-item-details {
    margin: 0;
  }
  .sidebar .product-items .product-item-actions {
    clear: left;
  }
  .compare.wrapper {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none none;
  }
  .compare.wrapper .action.compare {
    line-height: 32px;
    color: #333333;
    text-decoration: none;
  }
  .compare.wrapper .action.compare:visited {
    color: #333333;
    text-decoration: none;
  }
  .compare.wrapper .action.compare:hover {
    color: #333333;
    text-decoration: underline;
  }
  .compare.wrapper .action.compare:active {
    color: #333333;
    text-decoration: underline;
  }
  .compare.wrapper .counter.qty {
    color: #7d7d7d;
  }
  .compare.wrapper .counter.qty:before {
    content: '(';
  }
  .compare.wrapper .counter.qty:after {
    content: ')';
  }
  .block-search {
    float: right;
    padding-left: 15px;
    position: relative;
    width: 250px;
    z-index: 4;
  }
  .block-search .control {
    border-top: 0;
    margin: 0;
    padding: 0;
  }
  .block-search input {
    margin: 0;
    padding-right: 35px;
    position: static;
  }
  .block-search input::-webkit-input-placeholder {
    color: #c2c2c2;
  }
  .block-search input:-moz-placeholder {
    color: #c2c2c2;
  }
  .block-search input::-moz-placeholder {
    color: #c2c2c2;
  }
  .block-search input:-ms-input-placeholder {
    color: #c2c2c2;
  }
  .block-search .action.search {
    display: inline-block;
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
  }
  .block-search .action.search > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .block-search .action.search:before {
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 32px;
    color: #8f8f8f;
    content: '\e615';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .block-search .action.search:hover:before {
    color: #333333;
  }
  .block-search .action.search:active:before {
    color: inherit;
  }
  .block-search .action.search:focus,
  .block-search .action.search:active {
    background: none;
    border: none;
  }
  .block-search .action.search:hover {
    background: none;
    border: none;
  }
  .block-search .action.search.disabled,
  .block-search .action.search[disabled],
  fieldset[disabled] .block-search .action.search {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .block-search .action.search:focus:before {
    color: #333333;
  }
  .search-autocomplete {
    margin-top: 0;
  }
  .checkout-cart-index .page-main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .cart-container .form-cart .actions.main {
    text-align: right;
  }
  .cart-container .widget {
    float: left;
  }
  .cart-summary {
    width: 25%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    padding: 1px 20px 25px;
    position: relative;
  }
  .cart-summary > .title {
    display: block;
  }
  .cart-summary .fieldset .actions-toolbar {
    margin-left: 0;
  }
  .cart-summary .fieldset .actions-toolbar > .secondary {
    float: none;
  }
  .cart-summary .block > .title {
    padding-left: 0;
  }
  .cart-summary .block > .title:after {
    right: 3px;
  }
  .cart-summary .block .fieldset .field {
    margin: 0 0 20px;
    margin: 0 0 10px;
  }
  .cart-summary .block .fieldset .field:not(.choice) > .label {
    box-sizing: content-box;
    float: none;
    width: auto;
    text-align: left;
    padding: 0;
  }
  .cart-summary .block .fieldset .field:not(.choice) > .control {
    float: none;
    width: auto;
  }
  .cart-summary .block .fieldset .field > .label {
    margin: 0 0 8px;
    display: inline-block;
  }
  .cart-summary .block .fieldset .field.choice:before,
  .cart-summary .block .fieldset .field.no-label:before {
    display: none;
  }
  .cart-summary .checkout-methods-items {
    padding: 0;
  }
  .cart.table-wrapper .items {
    min-width: 100%;
    width: auto;
  }
  .cart.table-wrapper tbody td {
    padding-top: 20px;
  }
  .cart.table-wrapper .item .col.item {
    padding: 20px 8px 20px 0;
  }
  .cart.table-wrapper .item-actions td {
    padding: 0;
  }
  .cart.table-wrapper .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding-right: 20px;
    position: static;
    vertical-align: top;
    width: 1%;
  }
  .cart.table-wrapper .product-item-details {
    padding-bottom: 35px;
  }
  .cart.table-wrapper .product-item-details {
    display: table-cell;
    vertical-align: top;
    white-space: normal;
    width: 99%;
  }
  .cart-discount {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    border: 0;
    box-sizing: border-box;
    padding-right: 4%;
  }
  .cart-discount .block .title:after {
    display: inline;
    margin-left: 10px;
    position: static;
  }
  .block.crosssell {
    width: 75%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding: 0 4% 0 0;
  }
  .block.crosssell .products-grid .product-item {
    width: 25%;
  }
  .minicart-wrapper {
    margin-left: 13px;
  }
  .minicart-wrapper .block-minicart {
    right: -15px;
    width: 390px;
  }
  .minilist .action.delete:before,
  .minicart-wrapper .action.edit:before {
    font-size: 16px;
    line-height: inherit;
  }
  .opc-wrapper {
    width: 66.66666667%;
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
    padding-right: 30px;
  }
  .opc-estimated-wrapper {
    display: none;
  }
  .opc-progress-bar {
    margin: 0 0 20px;
    counter-reset: i;
    display: block;
    font-size: 0;
  }
  .opc-progress-bar-item {
    margin: 0 0 10px;
    width: 185px;
    display: inline-block;
    position: relative;
    text-align: center;
    vertical-align: top;
  }
  .opc-progress-bar-item:before {
    background: #e4e4e4;
    top: 19px;
    transition: background 0.3s;
    content: '';
    height: 7px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .opc-progress-bar-item:first-child:before {
    border-radius: 6px 0 0 6px;
  }
  .opc-progress-bar-item:last-child:before {
    border-radius: 0 6px 6px 0;
  }
  .opc-progress-bar-item > span {
    display: inline-block;
    padding-top: 45px;
    width: 100%;
    word-wrap: break-word;
    color: #e4e4e4;
    font-weight: 300;
    font-size: 1.8rem;
  }
  .opc-progress-bar-item > span:before,
  .opc-progress-bar-item > span:after {
    background: #e4e4e4;
    height: 38px;
    margin-left: -19px;
    transition: background 0.3s;
    width: 38px;
    border-radius: 50%;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
  }
  .opc-progress-bar-item > span:after {
    background: #ffffff;
    height: 26px;
    margin-left: -13px;
    top: 6px;
    width: 26px;
    content: counter(i);
    counter-increment: i;
    color: #333333;
    font-weight: 600;
    font-size: 1.8rem;
  }
  .opc-progress-bar-item._complete {
    cursor: pointer;
  }
  .opc-progress-bar-item._complete:hover:before {
    background: #d7d7d7;
  }
  .opc-progress-bar-item._complete:hover > span:before {
    background: #d7d7d7;
  }
  .opc-progress-bar-item._complete > span {
    color: #1979c3;
  }
  .opc-progress-bar-item._complete > span:after {
    font-family: 'luma-icons';
    content: '\e610';
  }
  .opc-progress-bar-item._active:before {
    background: #ff5501;
  }
  .opc-progress-bar-item._active > span {
    color: #333333;
  }
  .opc-progress-bar-item._active > span:before {
    background: #ff5501;
  }
  .opc-progress-bar-item._active > span:after {
    font-family: 'luma-icons';
    content: '\e610';
  }
  .checkout-index-index .modal-popup .form-shipping-address {
    max-width: 500px;
  }
  .checkout-index-index .modal-popup .modal-footer .action-save-address {
    float: right;
    margin: 0 0 0 20px;
  }
  .checkout-shipping-method .actions-toolbar > .primary {
    float: right;
  }
  .checkout-shipping-method .actions-toolbar .action.primary {
    margin: 0;
  }
  .opc-wrapper .form-login,
  .opc-wrapper .form-shipping-address {
    max-width: 500px;
  }
  .opc-wrapper .form-login {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 20px;
  }
  .table-checkout-shipping-method {
    width: auto;
  }
  .opc-sidebar {
    margin: 46px 0 20px;
    width: 33.33333333%;
    float: right;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .opc-summary-wrapper .modal-header .action-close {
    display: none;
  }
  .authentication-dropdown {
    background-color: #ffffff;
    border: 1px solid #aeaeae;
    -webkit-transform: scale(1, 0);
    -webkit-transform-origin: 0 0;
    -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0.1s;
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear .1s, visibility 0s linear .1s;
    visibility: hidden;
    width: 100%;
  }
  .authentication-dropdown._show {
    z-index: 100;
    -webkit-transform: scale(1, 1);
    -webkit-transition: -webkit-transform linear 0.1s, visibility 0s linear 0s;
    transform: scale(1, 1);
    transition: transform linear .1s, visibility 0s linear 0s;
    visibility: visible;
  }
  .authentication-wrapper {
    width: 33.33333333%;
    text-align: right;
  }
  .block-authentication .block-title {
    font-size: 2.6rem;
    border-bottom: 0;
    margin-bottom: 25px;
  }
  .block-authentication .actions-toolbar > .primary {
    display: inline;
    float: right;
    margin-right: 0;
  }
  .block-authentication .actions-toolbar > .primary .action {
    margin-right: 0;
  }
  .block-authentication .actions-toolbar > .secondary {
    float: left;
    margin-right: 2rem;
    padding-top: 1rem;
  }
  .popup-authentication .modal-inner-wrap {
    min-width: 768px;
    width: 60%;
  }
  .popup-authentication .block-authentication {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    border-top: 1px solid #c1c1c1;
  }
  .popup-authentication .block[class],
  .popup-authentication .form-login,
  .popup-authentication .fieldset,
  .popup-authentication .block-content {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
  .popup-authentication .block[class] {
    box-sizing: border-box;
    float: left;
    padding: 10px 30px 0 0;
    width: 50%;
  }
  .popup-authentication .block[class] + .block {
    border-left: 1px solid #c1c1c1;
    border-top: 0;
    margin: 0;
    padding: 10px 0 0 40px;
  }
  .popup-authentication .block[class] + .block::before {
    left: 0;
    top: 50%;
  }
  .popup-authentication .actions-toolbar {
    margin-bottom: 0;
    margin-top: auto;
  }
  .checkout-payment-method .actions-toolbar .primary {
    float: left;
    margin: 0;
  }
  .checkout-payment-method .fieldset > .field-select-billing > .control {
    float: none;
    width: 100%;
  }
  .checkout-billing-address .action-update {
    float: right;
  }
  .checkout-billing-address .actions-toolbar .action-cancel {
    margin: 6px 20px 0 0;
  }
  .checkout-payment-method .payment-option-title {
    padding-left: 22px;
  }
  .checkout-payment-method .payment-option-content .payment-option-inner + .actions-toolbar {
    margin-left: 0;
  }
  .login-container .block.login .actions-toolbar > .primary {
    margin-bottom: 0;
    margin-right: 30px;
  }
  .login-container .block.login .actions-toolbar > .secondary {
    float: left;
  }
  .login-container .fieldset > .field > .control {
    width: 55%;
  }
  .form-create-account .fieldset-fullname .fields .field {
    float: left;
    margin: 0 10px 10px 0;
  }
  .form-create-account .fieldset-fullname .field-name-prefix,
  .form-create-account .fieldset-fullname .field-name-suffix {
    width: 50px;
  }
  .form.password.reset,
  .form.send.confirmation,
  .form.password.forget,
  .form.create.account {
    min-width: 600px;
    width: 50%;
  }
  .account.page-layout-2columns-left .sidebar-main,
  .account.page-layout-2columns-left .sidebar-additional {
    width: 22.3%;
  }
  .account.page-layout-2columns-left .column.main {
    width: 77.7%;
  }
  .account .data.table {
    margin-bottom: 0;
  }
  .account .data.table .col.actions {
    white-space: nowrap;
  }
  .block-addresses-list .items.addresses > .item {
    margin-bottom: 20px;
  }
  .block-addresses-list .items.addresses > .item:nth-last-child(1),
  .block-addresses-list .items.addresses > .item:nth-last-child(2) {
    margin-bottom: 0;
  }
  .form-edit-account .fieldset .fieldset {
    margin-bottom: 20px;
    width: 100%;
  }
  .control.captcha-image .captcha-img {
    margin: 0 10px 10px 0;
  }
  .storecredit .block-balance-history {
    width: 100%;
  }
  .table-balance-history .col {
    width: 25%;
  }
  .page-product-downloadable .product-options-wrapper {
    float: left;
    width: 55%;
  }
  .page-product-downloadable .product-options-bottom {
    float: right;
    width: 40%;
  }
  .page-product-giftcard .product-info-main .price-box .price-container.price-final {
    font-size: 21px;
  }
  .page-product-giftcard .product-info-main .price-box .price-container.price-final .price {
    font-size: 36px;
    line-height: 36px;
  }
  .page-product-giftcard .product-info-main .product-info-stock-sku {
    padding-bottom: 0;
  }
  .page-product-giftcard .product-info-main .product-reviews-summary {
    float: none;
  }
  .page-product-giftcard .product-add-form {
    margin-top: -20px;
  }
  .page-product-giftcard .product-add-form .field:not(.text) {
    margin: 0 0 20px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
  }
  .page-product-giftcard .product-add-form .field:not(.text) > .label {
    margin: 0 0 8px;
    display: inline-block;
  }
  .page-product-giftcard .product-add-form .field:not(.text):last-child {
    margin-bottom: 0;
  }
  .page-product-giftcard .product-add-form .field:not(.text) + .fieldset {
    clear: both;
  }
  .page-product-giftcard .product-add-form .field:not(.text) > .label {
    font-weight: 600;
  }
  .page-product-giftcard .product-add-form .field:not(.text) > .label + br {
    display: none;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .choice input {
    vertical-align: top;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group:before,
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    content: '';
    display: table;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group:after {
    clear: both;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group .field {
    box-sizing: border-box;
    float: left;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-2 .field {
    width: 50% !important;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-3 .field {
    width: 33.3% !important;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-4 .field {
    width: 25% !important;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .fields.group.group-5 .field {
    width: 20% !important;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    width: 100%;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon textarea,
  .page-product-giftcard .product-add-form .field:not(.text) .addon select,
  .page-product-giftcard .product-add-form .field:not(.text) .addon input {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
    display: inline-block;
    margin: 0;
    width: auto;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore,
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter {
    background: #ffffff;
    background-clip: padding-box;
    border: 1px solid #c2c2c2;
    border-radius: 1px;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 32px;
    line-height: 1.42857143;
    padding: 0 9px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:disabled,
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:disabled {
    opacity: 0.5;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-moz-placeholder,
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-moz-placeholder {
    color: #c2c2c2;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore::-webkit-input-placeholder,
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter::-webkit-input-placeholder {
    color: #c2c2c2;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore:-ms-input-placeholder,
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addafter:-ms-input-placeholder {
    color: #c2c2c2;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .addon .addbefore {
    float: left;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .additional {
    margin-top: 10px;
  }
  .page-product-giftcard .product-add-form .field:not(.text).required > .label:after,
  .page-product-giftcard .product-add-form .field:not(.text)._required > .label:after {
    content: '*';
    color: #e02b27;
    font-size: 1.2rem;
    margin: 0 0 0 5px;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .note {
    font-size: 1.2rem;
    margin: 3px 0 0;
    padding: 0;
    display: inline-block;
    text-decoration: none;
  }
  .page-product-giftcard .product-add-form .field:not(.text) .note:before {
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    line-height: 12px;
    font-family: 'luma-icons';
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .page-product-giftcard .product-add-form .field:not(.text):nth-child(odd) {
    padding-right: 25px;
  }
  .page-product-giftcard .product-add-form .field:not(.text):nth-child(even) {
    padding-right: 0;
  }
  .page-product-giftcard .product-add-form .product-options-wrapper .field:not(.date) > .control {
    width: auto;
  }
  .gift-message .field {
    margin: 0 0 20px;
    box-sizing: border-box;
  }
  .gift-message .field > .label {
    margin: 0;
  }
  .gift-message .field:before,
  .gift-message .field:after {
    content: '';
    display: table;
  }
  .gift-message .field:after {
    clear: both;
  }
  .gift-message .field.choice:before,
  .gift-message .field.no-label:before {
    padding: 6px 15px 0 0;
    width: 25.8%;
    box-sizing: border-box;
    content: ' ';
    float: left;
    height: 1px;
  }
  .gift-message .field .description {
    padding: 6px 15px 0 0;
    text-align: left;
    width: 25.8%;
    box-sizing: border-box;
    float: left;
  }
  .gift-message .field:not(.choice) > .label {
    padding: 6px 15px 0 0;
    text-align: left;
    width: 25.8%;
    box-sizing: border-box;
    float: left;
  }
  .gift-message .field:not(.choice) > .control {
    width: 74.2%;
    float: left;
  }
  .gift-options {
    position: relative;
    z-index: 1;
  }
  .gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
  }
  .gift-options .actions-toolbar .secondary {
    float: right;
  }
  .gift-options .actions-toolbar .secondary .action {
    float: right;
    margin-left: 20px;
    margin-right: 0;
  }
  .gift-options .actions-toolbar .secondary .action-cancel {
    display: block;
    float: left;
    margin-top: 6px;
  }
  .gift-options .actions-toolbar:nth-child(3):before {
    border-left: 1px solid #c1c1c1;
    bottom: 5rem;
    content: '';
    display: block;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 0;
  }
  .gift-options-title {
    font-weight: 300;
    font-size: 1.8rem;
  }
  .gift-item-block .title {
    padding: 20px 0;
  }
  .item-gift td {
    padding-left: 0;
    padding-right: 0;
  }
  .cart.table-wrapper .action-gift {
    float: left;
    display: inline-block;
    text-decoration: none;
  }
  .cart.table-wrapper .action-gift:after {
    -webkit-font-smoothing: antialiased;
    font-size: 1.2rem;
    line-height: 20px;
    color: #8f8f8f;
    content: '\e622';
    font-family: 'luma-icons';
    margin: 0 0 0 10px;
    vertical-align: middle;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .cart.table-wrapper .action-gift:hover:after {
    color: #333333;
  }
  .cart.table-wrapper .action-gift:active:after {
    color: #8f8f8f;
  }
  .cart.table-wrapper .action-gift._active:after {
    content: '\e621';
  }
  .cart.table-wrapper .gift-options .actions-toolbar {
    clear: both;
    padding: 0;
    position: static;
  }
  .cart-container .cart-gift-item {
    margin-bottom: 20px;
  }
  .cart-container .cart-gift-item .gift-options,
  .cart-container .cart-gift-item .gift-summary {
    padding-bottom: 20px;
  }
  .form-giftregistry-share .fieldset .field,
  .form-giftregistry-edit .fieldset .field {
    width: 45%;
  }
  .form-giftregistry-edit .fieldset.shipping_address .fieldset {
    margin-top: 20px;
  }
  .table-giftregistry .col.create {
    white-space: nowrap;
  }
  .table-giftregistry .col.message {
    width: 40%;
  }
  .table-giftregistry .col.actions {
    width: 30%;
  }
  .table-giftregistry-items .col.note {
    width: 30%;
  }
  .account .data.table.table-giftregistry-items > thead > tr > th,
  .account .data.table.table-giftregistry-items > tbody > tr > th,
  .account .data.table.table-giftregistry-items > tfoot > tr > th,
  .account .data.table.table-giftregistry-items > thead > tr > td,
  .account .data.table.table-giftregistry-items > tbody > tr > td,
  .account .data.table.table-giftregistry-items > tfoot > tr > td {
    border-top: 1px solid #cccccc;
  }
  .account .data.table.table-giftregistry-items > caption + thead > tr:first-child > th,
  .account .data.table.table-giftregistry-items > colgroup + thead > tr:first-child > th,
  .account .data.table.table-giftregistry-items > thead:first-child > tr:first-child > th,
  .account .data.table.table-giftregistry-items > caption + thead > tr:first-child > td,
  .account .data.table.table-giftregistry-items > colgroup + thead > tr:first-child > td,
  .account .data.table.table-giftregistry-items > thead:first-child > tr:first-child > td {
    border-top: 0;
  }
  .account .data.table.table-giftregistry-items > tbody + tbody {
    border-top: 1px solid #cccccc;
  }
  .account .data.table.table-giftregistry-items th {
    border-bottom: 0;
  }
  .account .data.table.table-giftregistry-items > tbody > tr:nth-child(even) > td,
  .account .data.table.table-giftregistry-items > tbody > tr:nth-child(even) > th {
    background: none;
  }
  .form-giftregistry-search .fieldset {
    margin-bottom: 29px;
  }
  .form-giftregistry-search .fieldset .field.name,
  .form-giftregistry-search .fieldset .field.lastname {
    padding: 0 12px 0 0;
    box-sizing: border-box;
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-bottom: auto;
  }
  .form-giftregistry-search .fieldset .field.name + .fieldset,
  .form-giftregistry-search .fieldset .field.lastname + .fieldset {
    clear: both;
  }
  .form-giftregistry-search .fieldset .field.lastname {
    padding: 0;
  }
  .block-giftregistry-shared-items .item {
    border-bottom: 1px solid #cccccc;
    border-top: 0;
  }
  .block-giftregistry-shared-items .product-item-photo {
    display: table-cell;
    max-width: 100%;
    padding: 0 20px 0 0;
    vertical-align: top;
    width: 1%;
  }
  .block-giftregistry-shared-items .product-item-details {
    display: table-cell;
    vertical-align: top;
    width: 99%;
    word-break: normal;
  }
  .block-giftregistry-shared-items .col.product {
    width: 48%;
  }
  .block-giftregistry-shared-items .col:not(.product) {
    text-align: center;
  }
  .block-giftregistry-shared-items .col.price {
    padding-top: 17px;
  }
  .block-giftregistry-shared-items .input-text.qty {
    margin-top: -4px;
  }
  .gift-wrapping {
    box-sizing: border-box;
    float: left;
    padding-right: 20px;
    width: 50%;
  }
  .gift-wrapping + .gift-message {
    border-left: 1px solid #c1c1c1;
    box-sizing: border-box;
    float: left;
    padding-left: 4.5rem;
    width: 50%;
  }
  .page-product-grouped .product-info-price {
    float: none;
  }
  .page-product-grouped .minimal-price {
    margin-top: -8px;
  }
  .table-invitations .col {
    width: 50%;
  }
  .filter.block {
    margin-bottom: 40px;
  }
  .filter-title {
    display: none;
  }
  .filter-content .item {
    margin: 10px 0;
  }
  .filter-actions {
    margin-bottom: 30px;
  }
  .filter.active .filter-options,
  .filter-options {
    background: transparent;
    clear: both;
    display: block;
    overflow: initial;
    position: static;
  }
  .filter-subtitle {
    display: block;
    position: static;
  }
  .page-layout-1column .toolbar-products {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .page-layout-1column .products ~ .toolbar-products {
    position: static;
  }
  .page-layout-1column.page-with-filter .column.main {
    padding-top: 45px;
    position: relative;
    z-index: 1;
  }
  .page-layout-1column .filter.block {
    border-top: 1px solid #cccccc;
  }
  .page-layout-1column .filter-content {
    margin-top: 10px;
  }
  .page-layout-1column .filter-subtitle {
    display: none;
  }
  .page-layout-1column .filter-options-item {
    border: 0;
    display: inline-block;
    margin-right: 25px;
    position: relative;
  }
  .page-layout-1column .filter-options-item.active {
    z-index: 2;
  }
  .page-layout-1column .filter-options-item.active .filter-options-content {
    visibility: visible;
  }
  .page-layout-1column .filter-options-item.active:hover {
    z-index: 3;
  }
  .page-layout-1column .filter-options-item.active:after,
  .page-layout-1column .filter-options-item.active:before {
    border: 8px solid transparent;
    height: 0;
    width: 0;
    border-bottom-color: #000000;
    bottom: -1px;
    content: '';
    display: block;
    left: 5px;
    position: absolute;
    z-index: 3;
  }
  .page-layout-1column .filter-options-item.active:after {
    border-bottom-color: #ffffff;
    margin-top: 2px;
    z-index: 4;
  }
  .page-layout-1column .filter-options-title {
    padding: 0 20px 0 0;
  }
  .page-layout-1column .filter-options-title:after {
    right: 2px;
    top: 3px;
    z-index: 3;
  }
  .page-layout-1column .filter-options-content {
    background: #ffffff;
    -webkit-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
    -ms-box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
    box-shadow: 0 3px 5px 0 rgba(50, 50, 50, 0.75);
    border: 1px solid #cccccc;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 180px;
    z-index: 2;
  }
  .page-layout-1column .filter-options-content .item {
    margin: 0;
    padding: 5px;
  }
  .page-layout-1column .filter-options-content .item a {
    margin-left: 0;
  }
  .page-layout-1column .filter-options-content .item:hover {
    background-color: #e8e8e8;
  }
  .page-layout-1column .filter-current {
    display: inline;
    line-height: 35px;
  }
  .page-layout-1column .filter-current-subtitle {
    color: #7d7d7d;
    display: inline;
    font-size: 14px;
    font-weight: normal;
    padding: 0;
  }
  .page-layout-1column .filter-current-subtitle:after {
    content: ':';
  }
  .page-layout-1column .filter-current .item,
  .page-layout-1column .filter-current .items {
    display: inline;
  }
  .page-layout-1column .filter-current .item {
    margin-right: 25px;
    white-space: nowrap;
  }
  .page-layout-1column .filter-current .action.remove {
    line-height: normal;
  }
  .page-layout-1column .filter-actions {
    display: inline;
    white-space: nowrap;
  }
  .page-layout-1column .filter-actions ~ .filter-options {
    margin-top: 25px;
  }
  .wishlist.window.popup {
    bottom: auto;
    top: 20%;
    left: 50%;
    margin-left: -212px;
    width: 380px;
    right: auto;
  }
  .block-wishlist-management {
    margin-bottom: 20px;
  }
  .block-wishlist-management .wishlist-select {
    border-bottom: 1px solid #e8e8e8;
    display: table;
    margin-bottom: 15px;
    width: 100%;
  }
  .block-wishlist-management .wishlist-select .wishlist-name {
    display: table-cell;
    margin-right: 10px;
    padding: 5px 10px 10px;
    vertical-align: top;
    white-space: nowrap;
    width: 5%;
  }
  .block-wishlist-management .wishlist-select-items {
    display: table-cell;
    padding-right: 160px;
    vertical-align: top;
  }
  .block-wishlist-management .wishlist-select-items .item {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px 10px;
  }
  .block-wishlist-management .wishlist-select-items .item:last-child {
    margin-right: 0;
  }
  .block-wishlist-management .wishlist-select-items .current {
    border-bottom: 3px solid #ff5501;
    font-weight: 600;
  }
  .block-wishlist-management .wishlist-select .wishlist-name-current {
    display: none;
  }
  .block-wishlist-management .wishlist-add.item {
    position: absolute;
    right: 0;
    top: 0;
  }
  .block-wishlist-management .wishlist-title strong {
    font-size: 4rem;
  }
  .block-wishlist-management .wishlist-info {
    float: left;
  }
  .block-wishlist-management .wishlist-toolbar {
    float: right;
  }
  .block-wishlist-info-items .product-item-photo {
    margin-left: 0;
  }
  .products-grid.wishlist .product-item-checkbox {
    float: left;
  }
  .products-grid.wishlist .product-item-checkbox + .product-item-name {
    margin-left: 25px;
  }
  .block.newsletter {
    width: 34%;
  }
  .product-reviews-summary {
    margin-bottom: 15px;
  }
  .products.wrapper.list .product-reviews-summary {
    margin: 0;
  }
  .product-reviews-summary .reviews-actions {
    font-size: 14px;
    margin-top: 3px;
  }
  .form-create-return .fieldset .field {
    width: 45%;
  }
  .magento-rma-guest-returns .column.main .block.block-order-details-view .block-content:not(.widget) .box {
    clear: none;
    float: left;
    width: 25%;
  }
  .block-returns-tracking .block-title .action {
    margin: 12px 0 0 30px;
  }
  .block-returns-tracking .block-title .action.track {
    float: right;
  }
  .order-links .item {
    float: left;
    margin: 0 -1px 0 0;
  }
  .order-links .item a {
    padding: 1px 35px;
  }
  .order-links .item strong {
    border-bottom: 0;
    margin-bottom: -1px;
    padding: 1px 35px 2px 35px;
  }
  .order-actions-toolbar .action.print {
    display: block;
    float: right;
  }
  .account .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  [class^='sales-guest-'] .column.main .block.block-order-details-view .block-content:not(.widget) .box,
  .sales-guest-view .column.main .block.block-order-details-view .block-content:not(.widget) .box {
    clear: none;
    float: left;
    width: 25%;
  }
  .block-order-details-comments {
    margin: 0 0 60px;
  }
  .block-order-details-comments .comment-date {
    clear: left;
    float: left;
    margin-right: 50px;
    max-width: 90px;
  }
  .block-order-details-comments .comment-content {
    overflow: hidden;
  }
  .order-details-items {
    margin-top: -1px;
    padding: 25px;
  }
  .order-details-items .col.name {
    padding-left: 0;
  }
  .order-details-items .col.price {
    text-align: center;
  }
  .order-details-items .col.subtotal {
    text-align: right;
  }
  .order-details-items tbody td {
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .order-details-items tfoot .amount,
  .order-details-items tfoot .mark {
    text-align: right;
  }
  .order-details-items.ordered .order-title {
    display: none;
  }
  .table-order-items tbody .col.label,
  .table-order-items tbody .col.value {
    padding-left: 0;
  }
  .table-order-items.invoice .col.qty,
  .table-order-items.shipment .col.qty {
    text-align: center;
  }
  .table-order-items.creditmemo .col.qty,
  .table-order-items.creditmemo .col.discount,
  .table-order-items.creditmemo .col.subtotal {
    text-align: center;
  }
  .table-order-items.creditmemo .col.total {
    text-align: right;
  }
  .form.send.friend {
    width: 60%;
  }
  .form.send.friend .fieldset .field {
    width: 45%;
  }
  .form.send.friend .fieldset .field.text {
    width: 90%;
  }
  html,
  body {
    height: 100%;
  }
  .ie9 body {
    background-color: #8f8f8f;
  }
  .navigation ul {
    padding: 0 8px;
  }
  .page-header {
    border: 0;
    margin-bottom: 0;
  }
  .page-header .panel.wrapper {
    border-bottom: 1px solid #e8e8e8;
    background-color: #7e807e;
  }
  .page-header .header.panel {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .page-header .switcher {
    float: right;
    margin-left: 15px;
    margin-right: -6px;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .page-main > .page-title-wrapper .page-title {
    display: inline-block;
  }
  .page-main > .page-title-wrapper .page-title + .action {
    float: right;
    margin-top: 20px;
  }
  .customer-welcome {
    display: inline-block;
    position: relative;
  }
  .customer-welcome:before,
  .customer-welcome:after {
    content: '';
    display: table;
  }
  .customer-welcome:after {
    clear: both;
  }
  .customer-welcome .action.switch {
    padding: 0;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
  }
  .customer-welcome .action.switch > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .customer-welcome .action.switch:after {
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    line-height: 22px;
    color: inherit;
    content: '\e622';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .customer-welcome .action.switch:hover:after {
    color: inherit;
  }
  .customer-welcome .action.switch:active:after {
    color: inherit;
  }
  .customer-welcome .action.switch.active {
    display: inline-block;
    text-decoration: none;
  }
  .customer-welcome .action.switch.active > span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .customer-welcome .action.switch.active:after {
    -webkit-font-smoothing: antialiased;
    font-size: 10px;
    line-height: 22px;
    color: inherit;
    content: '\e621';
    font-family: 'luma-icons';
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: normal;
    overflow: hidden;
    speak: none;
    text-align: center;
  }
  .customer-welcome .action.switch.active:hover:after {
    color: inherit;
  }
  .customer-welcome .action.switch.active:active:after {
    color: inherit;
  }
  .customer-welcome ul {
    margin: 0;
    padding: 0;
    list-style: none none;
    background: #ffffff;
    border: 1px solid #bbbbbb;
    margin-top: 4px;
    min-width: 100%;
    z-index: 101;
    box-sizing: border-box;
    display: none;
    top: 100%;
    right: 0;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  }
  .customer-welcome ul li {
    margin: 0;
    padding: 0;
  }
  .customer-welcome ul li:hover {
    background: #e8e8e8;
    cursor: pointer;
  }
  .customer-welcome ul:before,
  .customer-welcome ul:after {
    border-bottom-style: solid;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    width: 0;
  }
  .customer-welcome ul:before {
    border: 6px solid;
    border-color: transparent transparent #ffffff transparent;
    z-index: 99;
  }
  .customer-welcome ul:after {
    border: 7px solid;
    border-color: transparent transparent #bbbbbb transparent;
    z-index: 98;
  }
  .customer-welcome ul:before {
    right: 10px;
    top: -12px;
  }
  .customer-welcome ul:after {
    right: 9px;
    top: -14px;
  }
  .customer-welcome.active {
    overflow: visible;
  }
  .customer-welcome.active ul {
    display: block;
    position: absolute;
  }
  .customer-welcome li a {
    color: #333333;
    text-decoration: none;
    display: block;
    line-height: 1.4;
    padding: 8px;
  }
  .customer-welcome li a:visited {
    color: #333333;
    text-decoration: none;
  }
  .customer-welcome li a:hover {
    color: #333333;
    text-decoration: none;
  }
  .customer-welcome li a:active {
    color: #333333;
    text-decoration: none;
  }
  .customer-welcome .customer-name {
    cursor: pointer;
  }
  .customer-welcome .customer-menu {
    display: none;
  }
  .customer-welcome .action.switch {
    background-image: none;
    background: none;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    color: #ffffff;
  }
  .customer-welcome .action.switch:focus,
  .customer-welcome .action.switch:active {
    background: none;
    border: none;
  }
  .customer-welcome .action.switch:hover {
    background: none;
    border: none;
  }
  .customer-welcome .action.switch.disabled,
  .customer-welcome .action.switch[disabled],
  fieldset[disabled] .customer-welcome .action.switch {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
  .customer-welcome .header.links {
    min-width: 175px;
  }
  .customer-welcome.active .action.switch:after {
    content: '\e621';
  }
  .customer-welcome.active .customer-menu {
    display: block;
  }
  .customer-welcome .greet {
    display: none;
  }
  .header.panel > .header.links {
    margin: 0;
    padding: 0;
    list-style: none none;
    float: right;
    margin-left: auto;
  }
  .header.panel > .header.links > li {
    display: inline-block;
    vertical-align: top;
  }
  .header.panel > .header.links > li {
    margin: 0 0 0 15px;
  }
  .header.panel > .header.links > li.welcome,
  .header.panel > .header.links > li > a {
    display: inline-block;
    line-height: 1.4;
  }
  .header.panel > .header.links > li.welcome a {
    color: #ffffff;
    padding-left: 5px;
  }
  .header.panel > .header.links > .authorization-link:after {
    content: attr(data-label);
    display: inline-block;
    margin: 0 -5px 0 5px;
  }
  .header.panel > .header.links > .customer-welcome + .authorization-link {
    display: none;
  }
  .header.content {
    padding: 30px 20px 0;
  }
  .logo {
    margin: -8px auto 25px 0;
  }
  .logo img {
    max-height: inherit;
  }
  .page-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    min-height: 100%;
    position: relative;
    transition: margin 0.3s ease-out 0s;
  }
  .page-wrapper > .breadcrumbs,
  .page-wrapper > .top-container,
  .page-wrapper > .widget {
    box-sizing: border-box;
    width: 100%;
  }
  .ie10 .page-wrapper,
  .ie11 .page-wrapper {
    height: 100%;
  }
  .page-footer {
    background: #f4f4f4;
    margin-top: auto;
    padding-bottom: 25px;
  }
  .page-footer .switcher .options ul.dropdown {
    bottom: -10px;
    left: 100%;
    margin: 0 0 0 20px;
    top: auto;
  }
  .page-footer .switcher .options ul.dropdown:before,
  .page-footer .switcher .options ul.dropdown:after {
    bottom: 13px;
    left: auto;
    right: 100%;
    top: auto;
  }
  .page-footer .switcher .options ul.dropdown:before {
    border-color: transparent #ffffff transparent transparent;
  }
  .page-footer .switcher .options ul.dropdown:after {
    border-color: transparent #bbbbbb transparent transparent;
    margin: 0 0 -1px -1px;
  }
  .footer.content {
    border-top: none;
  }
  .footer.content .block {
    float: right;
  }
  .footer.content ul {
    padding-right: 50px;
  }
  .footer.content .switcher.store {
    display: block;
    margin: 0;
  }
  .footer.content .links {
    display: inline-block;
    padding-right: 50px;
    vertical-align: top;
  }
  .footer.content .links li {
    background: transparent;
    border: none;
    font-size: 14px;
    margin: 0 0 8px;
    padding: 0;
  }
  .footer.content .links a,
  .footer.content .links strong {
    display: inline;
  }
  .my-credit-cards .card-type img {
    display: block;
  }
  .products-grid.wishlist .product-item-info:hover .product-item-inner {
    display: block;
  }
  .products-grid.wishlist .product-item-tooltip {
    display: inline-block;
  }
  .products-grid.wishlist .product-item-actions {
    margin: 10px 0 0;
  }
  .products-grid.wishlist .product-item-actions > * {
    display: inline-block;
    margin-bottom: 7px;
    margin-top: 7px;
  }
  .products-grid.wishlist .product-item .fieldset {
    display: table;
  }
  .products-grid.wishlist .product-item .fieldset .field.qty,
  .products-grid.wishlist .product-item .fieldset .product-item-actions {
    display: table-cell;
    vertical-align: bottom;
  }
  .products-grid.wishlist .product-item .fieldset .field.qty {
    padding-right: 10px;
  }
  .products-grid.wishlist .product-item .box-tocart .actions-primary {
    margin: 0;
  }
  .products-grid.wishlist .product-item .box-tocart .stock {
    margin: 20px 0 0;
  }
  .products-grid.wishlist .product-item .tocart {
    width: auto;
  }
  .wishlist-index-index .product-item-info {
    width: auto;
  }
  .wishlist-index-index .product-item-inner {
    background: #ffffff;
    border: 1px solid #bbbbbb;
    border-top: none;
    box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.3);
    display: none;
    left: 0;
    margin: 9px 0 0 -1px;
    padding: 0 9px 9px;
    position: absolute;
    right: -1px;
    z-index: 2;
  }
  .wishlist-index-index .product-item-inner .comment-box {
    margin-top: -18px;
  }
  .block.widget .products-grid .product-item {
    width: 33.33333333%;
  }
  .page-layout-1column .block.widget .products-grid .product-item {
    width: 25%;
  }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 50%;
  }
  .sidebar .block.widget .pager .pages-item-next {
    padding: 0;
  }
  .sidebar .block.widget .pager .pages-item-next .action {
    margin: 0;
  }
}
@media all and (min-width: 769px), print {
  .modal-popup.modal-slide .modal-footer {
    border-top: 1px solid #c1c1c1;
    text-align: right;
  }
}
@media all and (min-width: 1024px), print {
  .products-grid .product-item {
    width: 20%;
  }
  .page-layout-1column .products-grid .product-item {
    width: 16.66666667%;
  }
  .page-layout-3columns .products-grid .product-item {
    width: 25%;
  }
  .page-products .products-grid .product-items {
    margin: 0;
  }
  .page-products .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%;
  }
  .page-products .products-grid .product-item:nth-child(4n + 1) {
    margin-left: 0;
  }
  .page-products.page-layout-1column .products-grid .product-item {
    margin-left: 0;
    width: 20%;
  }
  .page-products.page-layout-3columns .products-grid .product-item {
    margin-left: 1%;
    width: 32.667%;
  }
  .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n) {
    margin-left: 1%;
  }
  .page-products.page-layout-3columns .products-grid .product-item:nth-child(3n + 1) {
    margin-left: 0;
  }
  .box-tocart .paypal:first-of-type {
    margin-top: 13px;
  }
  .checkout-index-index .modal-popup .modal-inner-wrap {
    margin-left: -400px;
    width: 800px;
    left: 50%;
  }
  .opc-wrapper .shipping-address-item {
    width: 33.33333333%;
  }
  .opc-wrapper .shipping-address-item:before {
    background: #cccccc;
    height: calc(100% - 20px);
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
  }
  .opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
    display: none;
  }
  .opc-wrapper .shipping-address-item.selected-item:before {
    display: none;
  }
  .opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
    display: none;
  }
  .table-checkout-shipping-method {
    min-width: 500px;
  }
  .wishlist-index-index .products-grid .product-items {
    margin: 0;
  }
  .wishlist-index-index .products-grid .product-item {
    margin-bottom: 20px;
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%;
  }
  .wishlist-index-index .products-grid .product-item:nth-child(4n + 1) {
    margin-left: 0;
  }
  .block.widget .products-grid .product-item {
    width: 20%;
  }
  .page-layout-1column .block.widget .products-grid .product-item {
    margin-left: calc((100% - 5 * (100%/6)) / 4);
    width: 16.66666667%;
  }
  .page-layout-1column .block.widget .products-grid .product-item:nth-child(4n + 1) {
    margin-left: calc((100% - 5 * (100%/6)) / 4);
  }
  .page-layout-1column .block.widget .products-grid .product-item:nth-child(5n + 1) {
    margin-left: 0;
  }
  .page-layout-3columns .block.widget .products-grid .product-item {
    width: 25%;
  }
  .block.widget .products-grid .product-items {
    margin: 0;
  }
  .block.widget .products-grid .product-item {
    margin-left: calc((100% - 4 * 24.439%) / 3);
    padding: 0;
    width: 24.439%;
  }
  .block.widget .products-grid .product-item:nth-child(4n + 1) {
    margin-left: 0;
  }
}
@media all and (min-width: 1440px), print {
  .sidebar .product-items .product-item-info .product-item-photo {
    float: none;
    left: 0;
    margin: 0;
    position: absolute;
    top: 0;
  }
  .sidebar .product-items .product-item-details {
    margin-left: 85px;
  }
}
