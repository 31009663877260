//
// Lato Thin Italic
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.eot');
  src: local('Lato Thin Italic'),
       local('Lato-ThinItalic'),
       url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.woff') format('woff'),
       url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-thinitalic/lato-thinitalic.svg#lato') format('svg');
  font-weight: 200;
  font-style: italic;
}
