/* Data Loader */
@mixin loader-keyframes($num) {
  $offset: ($loader-overall-duration - $loader-single-duration) / 4;
  $start: 100 * (($num - 1) * $offset) / $loader-overall-duration;
  $end: 100 * ((($num - 1) * $offset) + $loader-single-duration) / $loader-overall-duration;
  $mid: 0% + ($end + $start) / 2;
  $start: 0% + $start;
  $end: 0% + $end;
  0% {
    opacity: 0;
  }
  
  50% {
    opacity: 1;
  }
  #{$start} {
    opacity: $loader-min-opacity;
  }
  #{$mid} {
    opacity: 1;
  }
  #{$end} {
    opacity: $loader-min-opacity;
  }
  100% {
    opacity: 0;
  }
}