//
// Lato Hairline
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-hairline/lato-hairline.eot');
  src: local('Lato Hairline'),
       local('Lato-Hairline'),
       url('#{$lato-font-path}/lato-hairline/lato-hairline.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-hairline/lato-hairline.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-hairline/lato-hairline.woff') format('woff'),
       url('#{$lato-font-path}/lato-hairline/lato-hairline.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-hairline/lato-hairline.svg#lato') format('svg');
  font-weight: 100;
  font-style: normal;
}
