body.page-layout-404{
    .page-title-wrapper{
        margin: 0 0 0;        
        background: url(../images/header_404.png) no-repeat center 35%;        
        background-size: cover;
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
        margin-top: 0 !important;
        border-bottom: 1px solid #8B8B8B !important;        
        height:243px;
        
        .page-title{
            .base{
                font-size: 0 !important;
            }
        }
    }
    .column.main{
        text-align: center;
        font-family: 'Lato', sans-serif;
        h1{ 
            font-size: 144px;            
            margin-bottom: 0px;
            font-weight: normal;
        }
        h2{
            margin-top: 0px;
            font-size: 48px;
            margin-bottom: 30px;
            font-weight: normal;
            i{
                background: url(../images/search-icon@2x.png) no-repeat center center;
                width: 35px;
                height: 35px;
                background-size: cover;
            }
        }
        p{
            font-size: 18px;
            line-height: 1.22;
        }
        a{
            color:$dodger-blue;
            &:hover{
                text-decoration: none;
            }
        }
    }
}