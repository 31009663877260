body.account {
    &.open_modal_history{
        overflow:hidden;
    }
    .orders-recent {
        border-bottom: 0;

        .table-order-items {
            thead {
                background-color: black;
            }

            tr {
                &.history-titles {
                    line-height: 49px;
                }

                &.empty {
                    td {
                        text-align: center;
                        position: relative;
                        padding-left: 10px !important;

                        .loader_ajax {
                            position: relative;
                            top: 50px;
                        }
                    }
                }

                &:nth-child(2n) {
                    background-color: $dark-grey-two;
                }

                /*&.order-history-info {
                    cursor: pointer;
                    border-left:2px solid transparent;

                    &:hover{
                        border-left-color: $dodger-blue;
                        background: #010101;
                    }
                }*/

                th {
                    line-height: 49px;

                    &:hover {
                        cursor: pointer;
                    }

                    &.sorted {
                        &:after {
                            display: inline-block;
                            content: ""; // .fa-caret-down
                            font-family: FontAwesome;
                            margin-left: 5px;
                        }

                        &.sort-asc {
                            &:after {
                                content: ""; // .fa-caret-up
                            }
                        }
                    }

                    &:first-child {
                        padding-left: 123px;
                    }
                }

                td {
                    line-height: 90px;

                    &:first-child {
                        padding-left: 123px;
                    }
                }
            }

            .order-history-line {
                width: calc(100% - 90px);
                height: 1px;
                background-color: #808080;;
                margin: 20px 0px 0px 0px;
            }
        }

        #my-orders-table {
            tfoot {
                background: transparent;

                td {
                    text-align: center;
                    border-top: 0 none;
                    padding-top: 30px;

                    #load-more-results {
                        display: none;
                        color: #fff;

                        &:hover {
                            text-decoration: none;
                            color: $dodger-blue;
                        }
                    }
                }
            }
        }
    }

    #history_order_filters_container {
        position: absolute;
        right: 10px;
        background-color: transparent !important;
        top: 125px;
        width: 60vw;
        display: block;

        ul.history_filter_list {
            list-style-type: none;
            display: inline-flex;
            li:first-child{                
                position: relative;
                input::-ms-clear {
                    display: none; 
                }

            }
            &>li {
                margin: 0px 4px !important;
            }
        }

        .calendar_icon {
            width: 25px;
            height: 25px;
            margin-top: 3px;
        }

        .li-date-separator {
            line-height: 30px;
        }
        .left-margin-10 {
            margin-left: 10px;

        }

        .search_icon{
            background-image: url(/media/loader/search.png);
            width: 15px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            top: 8px;
            right: 5px;
        }
        .search-orders-go {
            height: 32px;
            margin-left: 20px;
            padding: 7px 10px;
            line-height: 16px;
            width: auto !important;
        }
        .search-orders-go>span {
            font-size: 14px;
            padding: 0px !important;
        }
    }

    /* The Modal (background) */
    .modal-history {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 10000;

        /* Modal Content/Box */
        .modal-content-history {
            background-color: #1c1c1c;
            margin: 94px auto;            
            border-top: 1px solid #888;
            width: 100%;
            color: #c2c2c2;
            height:calc(100vh - 94px);
            position: fixed;
            overflow-y: auto;
            margin-bottom: 50px;

            td{
                strong{
                    font-weight: normal;
                }
            }

            .header_modal{
                border-bottom: 2px solid #979797;
                padding-top: 24px;
                padding-bottom: 30px;
                background-color: #040404;
                padding-left: 33px;
                margin-bottom: 70px;

                .close_modal{
                    font-size: 45px;
                    &:hover {
                        color: white;
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }

            .order-history-item {
                margin-bottom: 75px;
                padding: 0px 35px;
                .top_table{
                    .order-history-line{
                        margin-top: 86px;
                    }

                }
                td{
                    color: white;

                    &.big_title, .big_title {
                        font-size: 36px;
                        font-weight: 200;
                        &strong{
                            font-weight: bold;
                        }                        

                    }
                    &.status{
                        font-size: 18px;
                        font-weight: 200;
                    }
                    &.order-total{
                        strong{
                            margin-left: 30px;
                        }    
                    }
                    &.order-date {
                        font-size: 22px;
                        font-weight: 200;
                    }
                    .order-history-line{
                        border: solid 1px $dark-grey-three;
                        margin-top: 0px;
                        margin-bottom: 36px;
                    }
                }

            }
        }

        .order-item-content{
            td{
                &.big_title{
                    font-size: 26px;
                    font-weight: 200;
                    strong{
                        float: left;
                        margin-right: 10px;
                    }
                }
            }
            .item_table_data.price{
                td{
                    padding-bottom:50px;
                }
            }
            #order-history-item-qty{
                text-align: right;
                font-size: 18px;
                font-weight: 300;
                margin-right: 50px;
                strong{
                    margin-left: 20px;
                }
            }
            .data_table{
                width: 60%;
                margin-top: 60px;

                tr.row {
                    margin-bottom: 47px;
                }
            }
        }

        .easyPaginateNav{
            a{
                font-size: 12px;
                letter-spacing: normal;
                margin: 0 8px 0 0;
                display: inline-block;
                line-height: 30px;
                color: $white;
                text-decoration: none;

                &.page{
                    padding: 0 5px;

                    &.current{
                        background: $dodger-blue;
                        line-height: 22px!important;
                        font-weight: 700;
                    }

                }

                &.next,
                &.prev{

                    background: $dodger-blue;
                    border: none;
                    border-radius: 0;
                    box-shadow: none;
                    color: $dodger-blue;
                }

                &.next:before,
                    &.prev:before{
                    -webkit-font-smoothing: antialiased;
                    font-size: 16px;
                    line-height: inherit;
                    color: $white;
                    font-family: luma-icons;
                    margin: 0;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: 400;
                    overflow: hidden;
                    margin: 0px;
                    speak: none;
                    text-align: center;
                    margin-left: 8px;
                }

                &.prev:before{
                    content: "\e617";
                }

                &.next:before{
                    content: "\e608";
                }
                
                &.last,
                &.first{
                    display: none;
                }
            }
            
        }
    }

}