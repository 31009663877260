//
// Lato Italic
// http://www.latofonts.com/
// ------------------------------------------------------

@font-face {
  font-family: 'Lato';
  src: url('#{$lato-font-path}/lato-italic/lato-italic.eot');
  src: local('Lato Italic'),
       local('Lato-Italic'),
       url('#{$lato-font-path}/lato-italic/lato-italic.eot?#iefix') format('embedded-opentype'),
       url('#{$lato-font-path}/lato-italic/lato-italic.woff2') format('woff2'),
       url('#{$lato-font-path}/lato-italic/lato-italic.woff') format('woff'),
       url('#{$lato-font-path}/lato-italic/lato-italic.ttf') format('truetype'),
       url('#{$lato-font-path}/lato-italic/lato-italic.svg#lato') format('svg');
  font-weight: 400;
  font-style: italic;
}
