@media (min-width: 1200px) and (max-width: 1319px) {
    .block-search{
        padding-left: 3%!important;
        width: 40% !important;
        float:left;
    }
}

@media (min-width: 1100px) and (max-width: 1200px) {
    .block-search{
        padding-left:5px !important;
        width: 38% !important;
        float:left;
    }
}

@media screen and (max-width: 1000px) {
    .minicart-wrapper, .miniquote-wrapper {
        /*width: 8%;
*/    }
}

@media (min-width: 588px) and (max-width: 1100px) {
    .hometext{
        right: 5% !important;
        bottom: 16% !important;
        font-size: 32px !important;
    }
}

@media screen and (max-width: 1100px) {
    .block-search{
        padding-left: 0% !important;
        width: 34% !important;
        float:left;
    }
    .search.results .toolbar-sorter.sorter {display:none;}
    .page-layout-2columns-left .sidebar-main { margin-bottom: 40px; }
    .page-products .columns{padding-top:0px;}
    .catalogsearch-result-index .page-title-wrapper .page-title{margin-left:25px;}
    .message.notice{top:0px !important; height:70px; }
}

@media screen and (max-width: 1010px) {
    .minicart-wrapper, .miniquote-wrapper {
        /*width: 10% !important;*/
    }

    .miniuser-link {
        /*background-position: 10% 9px !important;*/
    }
}

@media screen and (min-width: 768px) and (max-width: 952px) {
    .block-search{
        padding-left: 0px !important;
        width: 27% !important;
    }

    .block-search .action.search {
        width:50px !important;
        margin-right: -0px !important;
    }
    .block-search input{padding-left:10px;}

    #search_mini_form{
        display:block !important;
        position:relative;
    }

}

@media screen and (max-width: 869px) {


    #search_mini_form{
        width: 100%;
        position: absolute;
    }
    .footer.content .links li{float:none;}
    .page-footer{padding-bottom:0px !important;}
}

@media (min-width: 952px){
    .guest-menu {
        display:none;
    }
}

@media screen and (max-width: 952px){

    .catalogsearch-advanced-result .page-title-wrapper{padding-top: 20px;}
    .page-layout-2columns-left .sidebar-main { display: none; }
    .catalogsearch-result-index .page-title-wrapper .page-title {
        margin-bottom: 40px;
    }

    .block-search {
        width: 100% !important;
    }

    .block-search input{
        position:relative !important;
        left:0px;
        margin: 0;
        height: 40px;
        width: 100%;
    }
    #search_mini_form{
        margin-top: 1px !important;
        left:0px;
        right:0px;
        margin:0 auto;
    }

    #maincontent{
        margin-top:50px;
    }

    .block-search .search{display:flex; height: 50px !important;}
    .block-search .control{
        border:0px !important;
        padding:0px !important;
        margin:0px !important;
        max-width: 100%;
        width: 100%;

    }
    .block-search .label{order:2;background:$dodger-blue;line-height: 40px;}
    .block-search .label::before{position:relative; left:5px; top:5px; color:white !important;}
    .block-search .search-autocomplete{margin-top:0px; }

    .block-search {
        padding-left: 0px !important;
        margin-top: 0px !important;
    }


    .block-search input {
        height: 50px;
        padding-left: 20px;
        font-size: 22px;
    }

    .catalogsearch-result-index .products-list {
        width: 100%;
    }

    .filter-title strong {
        background-color: #1b1b1b;
        border-radius: 0px;
        box-shadow: none;
        border: none;
    }

    .category-product-images {
        padding-left: 0px;
        padding-right: 0px;
        height: 30px;
        line-height: 0px;
    }

    .category-name-price .product-item-name {
        width: 100%;
    }

    .product-item-actions {
        display: block;
    }

    .category-addtocart {
        width: 100%;
        float: right;
    }
    .catalog-product-view .product-info-main {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 10px;
        padding-top: 20px;
    }

    div#tab-label-specificationstab {
        margin-left: 5px !important;
    }

    .product.data.items>.item.title>.switch:visited, .product.data.items>.item.title>.switch {
        color: #ffffff !important
    }

    div .product.data.items>.item.content {
        padding-left: 5% !important;
    }

}

@media (max-width: 734px) {
    .singlebrand{
        margin-right:5px !important;
    }
}

@media (max-width: 539px) {
    .minicart-wrapper {
        margin-top: 10px; }
}

@media (max-width: 588px) {
    .homebrands{
        max-width:94%;
        flex-direction:column;
        box-sizing:border-box;
        margin: 0 auto !important;
    }
    .homebrands .singlebrand{margin:10px 0px;}

    .hometext{
        right: 3% !important;
        bottom: 14% !important;
        font-size: 22px !important;
        line-height: 20px !important;
    }

}



@media screen and (max-width: 378px) {
    .logo{display:block !important; max-width:190px !important; float:left; }
    .page-header{height:auto !important;}
    #search_mini_form{margin-top: 0px !important;}
    .miniquote-wrapper{margin-top:10px !important;}
    .minicart-wrapper,.miniquote-wrapper{margin:0 3%; }
    .header.content {
        padding: 0px 0px 0 !important;
        height: 72px;
    }
}