/* Minicart / Miniquote
----------------------------------- */
/*
*
* Temporary Hack for Pricing
*
*/
/*New Line for Hack*/
.minicart-wrapper{
    .subtotal{
        display:none !important;
    }
}

.minicart-wrapper,
.miniquote-wrapper {
    text-align: center;
    height: 93px;
    margin: 0 !important;
    /* position: absolute; */
    /* right: 0; */
    cursor: pointer;
    min-width: 10%;    
    @include grid-media($desktop-growing) {
        min-width: 100px;
    }    

    &.active {
        background-color: $black-aar-two;
    }

    .block-minicart, 
    .block-miniquote {
        background: #313131;
        border:0 !important;

        &:before,
            &:after {
            display:none !important;
        }

        .action.close{  
            display:none;
            &:hover{
                color: white;
            }
        }
    }

    .miniquote-items-wrapper,
    .minicart-items-wrapper {
        padding: 0 30px 20px;

        .miniquote-items,
        .minicart-items {
            .product-item-details {
                padding-left: 0px !important;
            }

            .product-item-pricing {
                font-size: 16px;
                font-weight: 300;
            }
        }

        .item-qty.quote-item-qty,  
        .item-qty.cart-item-qty {
            background: none !important;
            border: none;
            float: left;
        }
    }

    .action.showcart,
    .action.showquote  {
        .counter.qty {
            background: $dodger-blue !important;
            border-radius: 100% !important;
            position: absolute;
            height: 12px;
            min-width: 7px;
        }
    }

    .block-miniquote {
        background: #313131;
        border:0 !important;
    }

    .subtitle.empty{
        color: white;
    }

    .counter-number {
        display: none;
    }

    .product-item-name {
        font-size: 18px;
        font-weight: 300;
        text-align: left;
    }

    .item.product.product-item {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $dark-grey-three;    
    }
}

/* Minicart */
.minicart-wrapper {
    .subtotal{
        label{
            color:white;
            span{
                color:white;
            }
        }
    }

    .block-content{
        .close{
            &:hover{
                color: white;
            }
        }
        .subtotal{
            .label{
                color:white;
                span{
                    color:white;
                }
            }
        }
    }

    .action.showcart{        
        &.active:before{
            font-size: 0;
        }
        .counter.qty {
            top: 21px;
            margin-left: -10px;
            &.empty {
                display: none !important;
            }
        }
        &:before {
            width: 40px !important;
            height: 51px !important;
            background-image:url('/media/wysiwyg/imagenes/carrito.png') !important;
            background-position:0 9px !important;
            background-repeat:no-repeat;
            padding-top:17px;
            content:"";
        }
        &:hover {
            /*height:100% !important;
            background-color:inherit !important;
            opacity:0.8;*/
        }
        .active{
            &:before {
                font-size: 40px;
                line-height: 40px;
                content:"" !important;
            }
        }
    }

    .block-minicart{
        width: 333px;
        margin-top: 0;
        right: 0;
        background: #1B1B1B !important;
        border:0;

        .viewcart{
            border:1px solid white;
            padding:14px 17px;
            display:block;
            margin:0 auto;
            color:white !important;

            span{
                font-weight:600 !important;
                font-size: 19px;
            }
        }

        .details-qty.qty {
            .update-cart-item{
                float:left;
            }
            input{
                width: 30%;
                text-align: left;
            }

            .label {
                width: 0px !important;
                padding-right: 0px;
                display: none;

                &:after {
                    display: none;
                }
            }

            .item-qty.cart-item-qty {
                margin-top: -2px;
                font-size: 16px;
                padding-left: 0px;
            }
        }

        .subtotal .label:after
            {
            display: none;
        }

        .price-container .minicart-price:after {
            content: 'x';
            padding-left: 7px;
        }

        .block-content {
            display: flex;
            flex-direction: column;

            >.actions {
                order: 4;
            }
        }

        .minicart-items-wrapper {
            order: 1;
            border:0;
            overflow-x:hidden;
            height: auto !important; 
            max-height: 305px;
            .action.edit {
                display: none;
            }

            .minicart-items{
                .product {
                    .options {
                        .toggle{
                            padding-right: 0px;
                            text-align: left;
                        }
                        .content {
                            text-align: left;
                        }
                    }
                    .product-item-details{
                        input, span:after {
                            color:white;
                        }
                        .product {                    
                            &.actions{
                                .secondary{
                                    a:hover:before{
                                        color:white;
                                    }
                                }
                            }
                        }
                    }
                }

                .product-item:not(:first-child) {
                    border-top: none;
                }
            }

            .price-container {
                float: left;
                margin-top: 0px;
                margin-right: 10px;
                font-size: 16px;
                font-weight: 300;

                .minicart-price .price {
                    font-size: 16px !important;
                }
            }

        }

        .items-total {
            display: none;
            order: 2;
        }

        .subtotal {
            order: 3;
            text-align: left;
            display: flex;
        }

        .minicart-items .action.delete:before,
            .cart.table-wrapper .action-delete:before {
            content: '\e616' !important;
        }

        .product-item-details {
            padding-bottom: 15px;
        }
    }
}

/* Miniquote */
.miniquote-wrapper {
    .action.showquote,
    .action.showquote.active {
        display: block !important;
        padding-top: 20px;
        margin-bottom: 0px;

        .counter.qty {
            top: 24px;
            margin-left: -16px;

            &.empty {
                display: none !important;
            }
        }
    }

    .miniquote-items {
        .item.product {
            float: left;
            clear: both;
            width: 100%;
            min-height: 90px;

            .options {
                text-align: left;
                margin-bottom: 0;

                .label, .values {
                    display: inline-block;
                    margin-bottom: 0;
                }

                .values {
                    span {
                        &:before {
                            content: ', ';
                            display: inline;
                            margin-left: -2px;
                        }

                        &:first-child {
                            &:before {
                                content: '';
                                display: none;
                            }  
                        }
                    }
                }

                .toggle {
                    padding: 0;

                    &:after {
                        font-family: 'luma-icons';
                    }
                }

                .content {
                    text-align: left;
                }
            }

            .product-item-pricing {
                display: block;

                &:after {
                    content: " ";
                    clear: both;
                    display: block;
                }



                .price-container {
                    float: left;
                    margin-top: 4px;
                    font-size: 16px;
                    font-weight: 300;
                }
            }
            
            .product-item-details {
                .details-qty {
                    margin-top: -3px;
                    float: left;
                }
            }
        }
    }

    .action.showquote{
        margin-top: 0px;

        .active {
            margin-bottom: 23px !important;
        }
        &:before {
            width: 40px !important;
            height: 51px !important;
            background-image:url('/media/wysiwyg/imagenes/minicarro.png') !important;
            background-position:0 4px !important;
        }
        &:hover {
            /*background-color:inherit !important;
            opacity:0.8;
            display:block;*/
        }
    }

    .block-miniquote{
        top: 20px;
        right: 0;
        background: #1B1B1B !important;
        border:0;
        margin-top:0 !important;
        padding: 25px 30px;

        .block-content {
            > .items-total { 
                display: none;
            }

            .actions {
                .action.checkout {
                    display: none;
                }
            }
        }

        .action.viewquote {
            color: white !important;
            text-decoration: none;
            font-size: 20px;
            font-weight: 500;
            border: solid 1px $dark-grey-three;
            padding: 11px 100px 15px 100px;
            display: block;
            widtt: 100%;
            box-sizing: border-box;

            &:hover {
                background-color: $dodger-blue;
                border: 1px solid $dodger-blue !important;
            }

            span {
                display: none;
            }

            &:after {
                content: 'View List';
            }
        }

        .miniquote-items-wrapper {
            margin: 0;
            padding: 0;
            padding-bottom: 20px;
            border:0;
            max-height: 312px;

            .miniquote-items {
                .product-item:not(:first-child){
                    border-top:none;
                }
            }
            .price-container {
                &:before {
                    content: '$RFQ';
                }
                &:after {
                    content: 'x';
                    margin-left: 10px;
                }
                .price-wrapper{
                    display: none;
                }
                .price {
                    display: none;
                }
            }

            .details-qty.qty .label {
                display: none;
            }
        }
    }

    .ui-dialog.ui-widget.ui-widget-content.ui-corner-all.ui-front.mage-dropdown-dialog {
        position: relative;
    }
}

.showcart {  margin-top: 17px; }
